import { useState, useEffect, Fragment, useRef } from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { DialogActions, MenuItem, Stack, FormControl, styled, Switch, Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Typography, Snackbar, Alert, useTheme, OutlinedInput, InputLabel, InputAdornment, Select, Divider } from '@mui/material';
import { debounce } from '@mui/material/utils';

import { AccessibilityNew, AddCircle, ArrowBack, Close, Warning, VisibilityOff, Visibility, CheckCircleOutline, Help } from "@mui/icons-material";
import DettaglioAlunno from "../dettaglio-utente/dettaglioAlunno";
import Presenza from "./presenza";
import AggiuntaEventi from "../eventi/aggiuntaEventi";

import useMediaQuery from '@mui/material/useMediaQuery';
import TimerBes from "./shared/besTimer";
import EventoChip from "./shared/eventoChip";
import Legende from "./shared/legende";
import { DateTime, Interval } from "luxon";
import { convertDataDiNascita, convertDataGetProssimeLezione, getDayMonthAndYearErrorDialog } from "../shared/metodiDateUtility";
import { PresenzeAPI } from "../../api/presenzeApi";
import { RegistroAPI } from "../../api/registroApi";
import { BesAPI } from "../../api/besApi";
import ElencoOreDaFirmare from "./elencoOreDaFirmare";

const TogglePresenza = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    transitionDuration: '100ms',
    color: '#f44336',
    '& + .MuiSwitch-track': {
      backgroundColor: '#f6685e',
      opacity: 1,
      border: 0,
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.2,
    },
    '&.Mui-checked': {
      color: '#2e7d32',
      '& + .MuiSwitch-track': {
        backgroundColor: '#81c784',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.3,
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: '#81c784',
      },
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: '#e57373',
    },

  },
}));

export default function RegistroDiClasse(props) {

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [ruoliUtente, setRuoliUtente] = useState({});
  const [permessiUtente, setPermessiUtente] = useState([]);
  const [tipo, setTipo] = useState(false);

  const [showIniziaAppello, setShowIniziaAppello] = useState(false);
  const [showModificaAppello, setShowModificaAppello] = useState(false);
  const [showFirmaOra, setShowFirmaOra] = useState(false);
  const [isConsultaRegistro, setIsConsultaRegistro] = useState(false);
  const [dataRichiesta, setDataRichiesta] = useState(null)
  const [idDocente, setIdDocente] = useState(-1);
  const [idCorso, setIdCorso] = useState(-1);
  const [idMateria, setIdMateria] = useState(-1);
  const [idOrario, setIdOrario] = useState(-1);
  const [nomeClasse, setNomeClasse] = useState("");
  const [oreDiLezioneClasseSelezionata, setOreDiLezioneClasseSelezionata] = useState([]);
  const [comunicazioniClasse, setComunicazioniClasse] = useState([]);
  const [addEventoTuttaClasse, setAddEventoTuttaClasse] = useState(false);
  const [alunniNonRitiratiNumber, setAlunniNonRitiratiNumber] = useState(0);
  const [nomeMateriaSelezionata, setNomeMateriaSelezionata] = useState(null);

  const [hasDocenteFunctions, setHasDocenteFunctions] = useState(true);

  const [hasLoaded, setHasLoaded] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMsg, setAlertMsg] = useState(false);
  const [dialogLegendaOpen, setDialogLegendaOpen] = useState(false);

  //Aggiunta evento
  const [aggiuntaEventoOpen, setAggiuntaEventoOpen] = useState(false);
  const [alunniSelezionati, setAlunniSelezionati] = useState([]);

  //Firma ora
  const [oraFirmata, setOraFirmata] = useState(false);
  const [showFirmaOraDialog, setShowFirmaOraDialog] = useState(false);
  const [pinConfermaOra, setPinConfermaOra] = useState("");
  const [showPasswordPinOra, setShowPasswordPinOra] = useState(false);
  const [idOrarioFirma, setIdOrarioFirma] = useState(-1);
  const [typeLezione, setTypeLezione] = useState(-1);
  const [oraSelezionata, setOraSelezionata] = useState(1);
  const [oreFirmate, setOreFirmate] = useState();
  const [codiceCorso, setCodiceCorso] = useState('');
  const [codiceSimon, setCodiceSimon] = useState('');

  //BES
  const [showBes, setShowBes] = useState(false);
  const [showBesPinDialog, setShowBesPinDialog] = useState(false);
  const [showPassword, setShowPassword] = useState(false)
  const [pinBesTmp, setPinBesTmp] = useState("")
  const [arrayBes, setArrayBes] = useState([]);

  //DETTAGLIO ALUNNO
  const [presenzaObj, setPresenzaObj] = useState(null);

  const [dettaglioAlunnoOpen, setDettaglioAlunnoOpen] = useState(false);
  const [besObjAlunnoSelezionato, setBesObjAlunnoSelezionato] = useState(null);
  const [nomeAlunnoSelezionato, setNomeAlunnoSelezionato] = useState("");
  const [idAlunnoSelezionato, setIdAlunnoSelezionato] = useState(null);
  const [indexTabToOpen, setIndexTabToOpen] = useState(0);

  //PRESENZE/ASSENZE
  const [statoAppello, setStatoAppello] = useState(0); //0 = appello da fare, 1 appello da confermare, 2 appello fatto, 3 modifica appello
  const [alunniAssenti, setAlunniAssenti] = useState([]);
  const [alunni, setAlunni] = useState([]);
  const [oreGiornata, setOreGiornata] = useState([]);
  const [allValidate, setAllValidate] = useState(false);

  const [sedeUtente, setSedeUtente] = useState('');


  const refReset = useRef(null);

  const refPinConfermaOra = useRef(null);

  const refPinConfermaBes = useRef(null);

  const [openDialogRitardo, setOpenDialogRitardo] = useState(false);
  const [dialogAppelloIsOpen, setDialogAppelloIsOpen] = useState(false);

  const ref = useRef({});

  const [modificaMode, setModificaMode] = useState(false);
  const [canAddEvents, setCanAddEvents] = useState(false);
  const [dataInizioPeriodo, setDataInizioPeriodo] = useState("");
  const [dataFinePeriodo, setDataFinePeriodo] = useState("");

  const [dialogElencoOreFirmaOpen, setDialogElencoOreFirmaOpen] = useState(false);
  const [btnConfermaAppelloDisabled, setBtnConfermaAppelloDisabled] = useState(true);
  const [canAddEventsPostPeriodo, setCanAddEventsPostPeriodo] = useState(false);

  useEffect(() => {
    if (props.isConsultaRegistro !== undefined)
      setIsConsultaRegistro(props.isConsultaRegistro)
  }, [props.isConsultaRegistro])

  useEffect(() => {
    if (props.codiceCorso !== undefined)
      setCodiceCorso(props.codiceCorso)
  }, [props.codiceCorso])

  useEffect(() => {
    if (props.codiceSimon !== undefined)
      setCodiceSimon(props.codiceSimon)
  }, [props.codiceSimon])

  useEffect(() => {
    if (props.idCorso !== undefined && props.dataRichiesta !== undefined && props.idMateria !== undefined && props.isConsultaRegistro !== undefined
      && props.idDocente !== undefined && props.oreDiLezioneClasseSelezionata !== undefined && props.idOrario !== undefined) {

      setIdCorso(props.idCorso);
      setDataRichiesta(props.dataRichiesta);
      setIdMateria(props.idMateria);
      //setIdDocente(props.idDocente);
      setIdOrario(props.idOrario);
      setIsConsultaRegistro(props.isConsultaRegistro);

      setOreDiLezioneClasseSelezionata([...props.oreDiLezioneClasseSelezionata]);
      getRegistroDiClasse(props.dataRichiesta, props.idCorso, props.idMateria, props.idDocente, props.oreDiLezioneClasseSelezionata, props.idOrario, props.isConsultaRegistro);

      let dataConverted = DateTime.fromSQL(props.dataRichiesta);
      dataConverted = convertDataGetProssimeLezione(dataConverted, false)


    }
  }, [props.idCorso, props.dataRichiesta, props.idMateria, props.idDocente, props.oreDiLezioneClasseSelezionata, props.idOrario, props.isConsultaRegistro])

  useEffect(() => {
    if (props.hasDocenteFunctions !== undefined)
      setHasDocenteFunctions(props.hasDocenteFunctions)
  }, [props.hasDocenteFunctions])

  useEffect(() => {
    if (props.permessiUtente !== undefined)
      setPermessiUtente([...props.permessiUtente])
  }, [props.permessiUtente])

  useEffect(() => {
    if (props.ruoliUtente !== undefined) {
      setRuoliUtente(props.ruoliUtente);
    }
  }, [props.ruoliUtente])

  useEffect(() => {
    if (props.sedeUtente !== undefined)
      setSedeUtente(props.sedeUtente)
  }, [props.sedeUtente])

  useEffect(() => {
    if (props.nomeClasse !== undefined)
      setNomeClasse(props.nomeClasse);
  }, [props.nomeClasse])

  useEffect(() => {
    if (props.tipo !== undefined)
      setTipo(props.tipo);
  }, [props.tipo])

  useEffect(() => {
    if (props.oraMateria !== undefined)
      setOraSelezionata(props.oraMateria)
  }, [props.oraMateria])

  useEffect(() => {
    if (props.nomeMateriaSelezionata !== undefined)
      setNomeMateriaSelezionata(props.nomeMateriaSelezionata)
  }, [props.nomeMateriaSelezionata])

  function logout() {
    if (props.logout) props.logout()
  }

  function vediDettaglioAlunno(id, indexTab, nome, cognome, ritardo, besObj, presenzaObj) {
    if (statoAppello !== 1 && statoAppello !== 3) { //Durante l'appello non puoi cliccare
      setPresenzaObj(presenzaObj)
      setOpenDialogRitardo(ritardo)
      setIdAlunnoSelezionato(id);
      setBesObjAlunnoSelezionato(besObj)
      setNomeAlunnoSelezionato(nome + " " + cognome);
      setDettaglioAlunnoOpen(true);
      setIndexTabToOpen(indexTab);
    }
  }

  function getIdOrarioAttuale(oreGiornata, idMateria, idDocente, idCorso, dataConverted, idOrario, isConsulta) {
    if (oreGiornata.length > 0) {
      let idOrarioTmp; // = oreDiLezioneClasseSelezionata[0].idOrario
      let checked = false;
      for (let ora of oreGiornata) {
        if (ora?.materia?.idMateria == idMateria && checked === false) {
          if (idOrario == null) {
            idOrarioTmp = ora?.id;
          }
          else idOrarioTmp = idOrario;
          checked = true;
        }
      }

      if (checked) {
        setIdOrarioFirma(idOrarioTmp);
        checkOraFirmata(idOrarioTmp, idDocente, idCorso, idMateria)
        getPermessiRE(idOrarioTmp, idCorso, dataConverted, isConsulta);

      } else { //SE non ho id orario sto su consulta
        getPermessiRE(null, idCorso, dataConverted, isConsulta);
      }


      if (idOrarioTmp !== undefined) //Su consulta registro di classe questo è undefined
        getPercentualeOreFirma(idOrarioTmp, idDocente, idCorso, idMateria);
    }
    /* for (let ora of oreGiornata) {
       if (oreDiLezioneClasseSelezionata.some((oraTmp => oraTmp?.idOrario === ora?.id))) {
         let idOrarioTmp = localStorage.getItem("oraLez");
         let idOrarioSelected = CryptoJS.AES.decrypt(idOrarioTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
         if (ora?.id == idOrarioSelected) {
           setOraSelezionata(ora?.ora)
         }
         setIdOrarioFirma(idOrarioSelected);
         checkOraFirmata(idOrarioSelected, idDocente)
 
 
       }
     }
     */
  }
  function closeDettaglioAlunno() {
    setDettaglioAlunnoOpen(false);
    getRegistroDiClasse(dataRichiesta, idCorso, idMateria, idDocente, oreDiLezioneClasseSelezionata, idOrario, isConsultaRegistro)
  }
  function openDialogLegenda() {
    setDialogLegendaOpen(true);
  }
  function closeDialogLegenda() {
    setDialogLegendaOpen(false);
  }
  //Metodi assenze/presenze
  function selectPresenza(e, idAlunno) {
    let alunniAssentiTmp = alunniAssenti;
    if (alunniAssentiTmp.includes(idAlunno)) {
      let index = alunniAssentiTmp.findIndex((alunno) => alunno === idAlunno);
      alunniAssentiTmp.splice(index, 1);
    } else {
      alunniAssentiTmp.push(idAlunno)
    }
    setAlunniAssenti([...alunniAssentiTmp])
  }
  function iniziaAppello() {
    setBtnConfermaAppelloDisabled(true)
    setTimeout(() => {
      setBtnConfermaAppelloDisabled(false)
    }, 400)
    setStatoAppello(1);
    openAlertMsg("Durante l'appello non è possibile effettuare altre operazioni", "info")
    // setDialogAppelloIsOpen(true);
  }
  function closeDialogAppello() {
    setDialogAppelloIsOpen(false);
    setBtnConfermaAppelloDisabled(true)
  }

  function confermaPresenze() {
    confirmAppello();
    closeDialogAppello();
  }
  function assenzaDiMassa() {
    let alunniAssentiTmp = [];
    for (let alunno of alunni) {
      if (alunno?.isIscritto === 1 && alunno?.isRitirato === 0)
        alunniAssentiTmp.push(alunno.id);
    }
    setAlunniAssenti([...alunniAssentiTmp]);
  }
  function confermaModifiche() {
    modificaAppello()
    closeDialogAppello()
  }

  function modificaPresenze() {
    setStatoAppello(3);
    setBtnConfermaAppelloDisabled(true)
    setTimeout(() => {
      setBtnConfermaAppelloDisabled(false)
    }, 400)
    openAlertMsg("Durante la modifica dell'appello non è possibile effettuare altre operazioni", "info")

    // setDialogAppelloIsOpen(true);
  }
  function annullaModifiche() {
    getRegistroDiClasse(dataRichiesta, idCorso, idMateria, idDocente, oreDiLezioneClasseSelezionata, idOrario, isConsultaRegistro)
    setStatoAppello(2);
    setDialogAppelloIsOpen(false);
    openAlertMsg("Modifiche annullate", "error")
  }


  //BES
  function besPinDialog() {
    setPinBesTmp("");
    setShowBesPinDialog(prev => !prev);
  }
  function confirmBesPin() {
    //se il pin è corretto
    if (pinBesTmp.length >= 1) {
      let dataObj = {
        pin: pinBesTmp,
        idCorso: idCorso,
        dataRichiesta: dataRichiesta
      }
      BesAPI.visualizzaBesDocenti(dataObj)
        .then((result) => {
          setShowBes(true);
          setShowBesPinDialog(false);
          openAlertMsg("Bes abilitati", "info");
          setArrayBes([...result])
          addBesToArray(alunni, result);

        })
        .catch((err) => {
          let errorMsg = "";
          if (err.response.status === 420) {
            errorMsg = err?.response?.data?.data[0]?.msg ?? "Pin errato";
          } else {
            errorMsg = "Pin errato";
          }
          openAlertMsg(errorMsg, "error")

        })
    } else openAlertMsg("Il campo PIN non può essere lasciato vuoto", "error")

  }
  function addBesToArray(alunni, result) {
    let alunniTmp = alunni;
    for (let i = 0; i < alunniTmp.length; i++) {
      let indexArrayBes = result.findIndex(a => a.id === alunniTmp[i].id)
      if (indexArrayBes !== -1) {
        alunniTmp[i].hasBes = result[indexArrayBes]?.hasBes ?? false;
        let interventoAttivo = result[indexArrayBes]?.BESData?.documentazioneConsegnataBes?.interventoAttivo;
        alunniTmp[i].besObj = {
          id: alunniTmp[i].id,
          hasBes: result[indexArrayBes]?.hasBes,
          colorBes: selColorBes(interventoAttivo),
          BESData: result[indexArrayBes]?.BESData
        }
      } else {
        alunniTmp[i].besObj = {
          hasBes: false
        }
      }
    }
    setAlunni([...alunniTmp]);
    setHasLoaded(true);

  }
  function selColorBes(valore) {
    let colorTmp;
    switch (valore) {
      case '0':
        colorTmp = '#4169e1';
        break;
      case '1':
        colorTmp = '#1ebf24';
        break;
      case '2':
        colorTmp = '#ffbfaa';
        break;
      case '3':
        colorTmp = '#ff7b5a';
        break;
      case '4':
        colorTmp = '#d11507';
        break;
      default:
        colorTmp = '';
    }
    return colorTmp
  }
  function stopViewBes() {
    setShowBes(false)
  }

  const changePinConfermaBesLength = debounce((event) => {
    setPinBesTmp(event.target.value);
  }, 100);

  //Firma ora
  function firmaOraPinDialog() {
    setPinConfermaOra("");
    setShowFirmaOraDialog(prev => !prev);
    setModificaMode(false);
  }
  function confirmOraPin() {
    //se il pin è corretto
    if (pinConfermaOra.length >= 1) {
      firmaOra();
    }
    else openAlertMsg("Il campo PIN non può essere lasciato vuoto", "error")

  }


  const changePinConfermaOra = debounce((event) => {
    setPinConfermaOra(event.target.value);
  });

  const changePinConfermaOraLength = debounce((event) => {
    setPinConfermaOra(event.target.value);
  }, 100);


  //Eventi
  function openAggiuntaEvento(alunnoObj, isTuttaClasse) {
    let alunniSelezionatiTmp = [];
    if (isTuttaClasse === undefined || isTuttaClasse === false) {
      setAddEventoTuttaClasse(false);
      alunniSelezionatiTmp.push(alunnoObj);
    } else {
      setAddEventoTuttaClasse(true);
      for (let alunno of alunni) {
        alunniSelezionatiTmp.push(alunno)
      }
    }
    setAlunniSelezionati([...alunniSelezionatiTmp]);
    setAggiuntaEventoOpen(true);
  }
  function closeAggiuntaEvento() {
    setAggiuntaEventoOpen(false);
  }
  function eventoAggiunto(alertMsg, alertSeverity) {
    setAggiuntaEventoOpen(false);
    openAlertMsg(alertMsg, alertSeverity)
    getRegistroDiClasse(dataRichiesta, idCorso, idMateria, idDocente, oreDiLezioneClasseSelezionata, idOrario, isConsultaRegistro)
  }


  //Funzioni utility
  function openAlertMsg(msg, severity) {
    setAlertMsg(msg);
    setAlertSeverity(severity);
    setAlertOpen(true);
  }
  function showPasswordInput() {
    setShowPassword(prev => !prev);
  }

  function showPasswordInputPin() {
    setShowPasswordPinOra(prev => !prev);
  }

  function isAlunnoIscrittoInData(dataCella, alunno) {
    let dataSql = DateTime.fromSQL(dataCella);
    let dataConvertedIso = dataSql.toUTC().toISO()
    let iscrittoDalIso = alunno?.iscrittoDal;
    let iscrittoAlIso = alunno?.iscrittoAl;

    let startDate = DateTime.fromISO(iscrittoDalIso);
    let endDate = DateTime.fromISO(iscrittoAlIso).plus({ days: 1 });
    let someDate = DateTime.fromISO(dataConvertedIso);
    let interval = Interval.fromDateTimes(startDate, endDate);
    if (interval.contains(someDate)) {
      return true
    } else return false;
  }

  function isAlunnoIscrittoInRange(dataDiInteresseInizio, dataDiInteresseFine, alunno) {
    let dataInizioTmp = convertDataGetProssimeLezione(dataDiInteresseInizio, true);
    let dataFineTmp = convertDataGetProssimeLezione(dataDiInteresseFine, true);

    let dataInizioObject = DateTime.fromISO(dataInizioTmp);
    let dataFineObject = DateTime.fromISO(dataFineTmp);

    let iscrittoDal = DateTime.fromISO(alunno?.iscrittoDal);
    let iscrittoAl = DateTime.fromISO(alunno?.iscrittoAl);
    if (alunno?.showDataRitiro == 1 || alunno?.showDataIscritto == 1) {
      if (iscrittoAl.startOf('day') < dataFineObject.startOf('day')) // Ritirato
      {
        return "Ritirato il " + convertDataDiNascita(iscrittoAl)
      }
      if (iscrittoAl.startOf('day') < dataInizioObject.startOf('day')) // Ritirato
      {
        return "Ritirato il " + convertDataDiNascita(iscrittoAl)
      }
      else //if (iscrittoDal.startOf('day') > dataInizioObject.startOf('day') && iscrittoDal.startOf('day') < dataFineObject.startOf('day')) // Non ancora iscritto in quella data
      {
        return "Iscritto dal " + convertDataDiNascita(iscrittoDal);
      }
    } else return "";
    // else if (iscrittoDal.startOf('day') > dataFineObject.startOf('day')) {
    //   return "Iscritto dal " + convertDataDiNascita(iscrittoDal);
    // }
    // else return null
  }


  function checkGravitaAssenza(dataAssenza) {
    const dataPartenza = DateTime.fromISO(dataAssenza);
    const dataOggi = DateTime.local();
    const diff = dataOggi.diff(dataPartenza, 'days').toObject();
    if (Math.floor(diff.days) > 2) return "red"
    else return "orange"
  }

  function changeTypeLezione(e) {
    setTypeLezione(e.target.value)
  }
  function goBackToVistaSintetica() {
    if (props.closeRegistro)
      props.closeRegistro();
  }

  function getRegistro() {
    getRegistroDiClasse(dataRichiesta, idCorso, idMateria, idDocente, oreDiLezioneClasseSelezionata, idOrario, isConsultaRegistro)
  }

  function canShowADG(alunno) {
    let today = convertDataGetProssimeLezione(dataRichiesta, true);
    if (alunno?.assenza !== undefined && alunno?.assenza?.data != today)
      return true
    else return false
  }

  function openElencoOreFirma() {
    let openTmp = !dialogElencoOreFirmaOpen;
    setDialogElencoOreFirmaOpen(openTmp);
  }



  //Funzioni chiamate
  async function getRegistroDiClasse(dataRichiesta, idCorso, idMateria, idDocente, oreDiLezioneClasseSelezionata, idOrario, isConsulta) {

    let dataConverted = DateTime.fromSQL(dataRichiesta);
    dataConverted = convertDataGetProssimeLezione(dataConverted, false);
    setHasLoaded(false)
    let dataObj = {
      "dataRichiesta": dataConverted,
      "idCorso": idCorso
    }
    RegistroAPI.getRegistroDiClasse(dataObj)
      .then((result) => {
        let alunniTmp = result.alunni;
        let oreTmp = result.ore;

        //Per aggiungere l'index della colonna
        for (let i = 0; i < alunniTmp.length; i++) {
          alunniTmp[i].msgIscrizione = isAlunnoIscrittoInRange(dataConverted, dataConverted, alunniTmp[i]);
          alunniTmp[i].giustificate = [];

          if (alunniTmp[i].comunicazioni.length > 0) {
            for (let j = 0; j < alunniTmp[i]?.comunicazioni.length; j++) {
              for (let ora of oreTmp) {
                if (ora?.comunicazioni.length > 0) {
                  for (let x = 0; x < ora?.comunicazioni.length; x++) {
                    if (alunniTmp[i].comunicazioni[j].contenuto?.idOrario != null) {
                      if (alunniTmp[i].comunicazioni[j].contenuto?.idOrario === ora?.id)
                        alunniTmp[i].comunicazioni[j].indexColonna = (ora?.ora - 1);
                      alunniTmp[i].comunicazioni[j].ora = ora?.ora;
                    } else { //Nota disciplinare perchè non ha un index
                      alunniTmp[i].comunicazioni[j].indexColonna = null
                    }
                  }
                } else {
                  if (alunniTmp[i].comunicazioni[j].contenuto.idOrario != null) {
                    if (alunniTmp[i].comunicazioni[j].contenuto.idOrario === ora?.id)
                      alunniTmp[i].comunicazioni[j].indexColonna = (ora?.ora - 1);
                    alunniTmp[i].comunicazioni[j].ora = ora?.ora;
                  } else { //Nota disciplinare perchè non ha un index
                    alunniTmp[i].comunicazioni[j].indexColonna = null
                  }
                }
              }

            }
          }
        }



        for (let i = 0; i < oreTmp.length; i++) {
          //Toglie i secondi dall'ora
          oreTmp[i].oraInizio = oreTmp[i]?.oraInizio.substring(0, oreTmp[i]?.oraInizio.length - 3);
          oreTmp[i].oraFine = oreTmp[i]?.oraFine.substring(0, oreTmp[i]?.oraFine.length - 3);
        }


        let numAlunniPresenti = 0;
        for (let alunno of alunniTmp) {
          if ((alunno?.isRitirato === 0 && alunno?.isIscritto === 1)) {
            numAlunniPresenti += 1;
          }
        }

        setAlunniNonRitiratiNumber(numAlunniPresenti);

        setComunicazioniClasse([...result?.classe?.comunicazioni])

        oreTmp.sort((a, b) => a?.ora - b?.ora);

        setOreGiornata([...oreTmp]);
        getPresenze(alunniTmp, dataRichiesta);


        //Controlla se è la prima ora e se c'è lezione in quel giorno
        getIdOrarioAttuale(oreTmp, idMateria, idDocente, idCorso, dataConverted, idOrario, isConsulta);

      })
      .catch(function (error) {
        setHasLoaded(true);
        setAlunni([]);
        setOreGiornata([])
      })

  }
  async function getAssenzeDaGiustificare(alunniArray, dataRichiesta) {
    let alunniArrayTmp = alunniArray;
    let idAlunni = [];
    for (let alunno of alunniArray) {
      idAlunni.push(alunno?.id)
    }

    let dataObj = {
      "dataDal": null,
      "dataAl": dataRichiesta,
      "idAlunno": idAlunni
    }

    PresenzeAPI.getAssenzeNonGiustificate(dataObj)
      .then((result) => {
        if (result.alunni !== undefined) {
          for (let assenza of result.alunni) {
            for (let i = 0; i < alunniArrayTmp.length; i++) {
              if (assenza?.idAlunno === alunniArrayTmp[i]?.id) {
                alunniArrayTmp[i].assenza = {
                  data: assenza?.dataPiuVecchia,
                  numeroAssenze: assenza?.numeroAssenze
                };
              }
            }
          }

        }

        getAssenzeGiustificate(alunniArrayTmp, dataRichiesta, dataRichiesta)

      })
      .catch((error) => {
      })
  }
  async function getPresenze(alunniTmp, dataRichiesta) {
    let idAlunni = [];
    for (let alunno of alunniTmp) {
      if ((alunno?.isRitirato === 0 && alunno?.isIscritto === 1)) {
        idAlunni.push(alunno?.id)
      }
    }

    let dataObj = {
      "data": dataRichiesta,
      "idAlunno": idAlunni
    }

    PresenzeAPI.getPresenzeGiornaliere(dataObj)
      .then((result) => {
        let newAlunniArray = alunniTmp;
        let presenzeValidate = [];

        if (result.alunni.length > 0) {
          let arrayAppello = result.alunni;
          for (let i = 0; i < newAlunniArray.length; i++) {
            for (let j = 0; j < arrayAppello.length; j++) {
              //if (alunno?.isRitirato === 0 && alunno?.isIscritto === 1) {
              if (newAlunniArray[i]?.id === arrayAppello[j]?.idAlunno) {
                let uscitaConverted = null;
                let ingressoConverted = null;
                if (arrayAppello[j]?.orario_uscita_reale != null)
                  uscitaConverted = arrayAppello[j]?.orario_uscita_reale.substring(0, arrayAppello[j]?.orario_uscita_reale.length - 3)
                if (arrayAppello[j]?.orario_ingresso_reale)
                  ingressoConverted = arrayAppello[j]?.orario_ingresso_reale.substring(0, arrayAppello[j]?.orario_ingresso_reale.length - 3)

                let objPresenza = {
                  id: arrayAppello[j]?.id,
                  data: arrayAppello[j]?.data,
                  statoPresenza: arrayAppello[j]?.stato_presenza,
                  note: arrayAppello[j]?.note,
                  orario_uscita_reale: uscitaConverted,
                  orario_ingresso_reale: ingressoConverted,
                  validata: arrayAppello[j]?.validata
                }

                if (arrayAppello[i]?.validata)
                  presenzeValidate.push(arrayAppello[i]?.id)

                newAlunniArray[i].presenza = objPresenza;
              }
            }
          }


          let alunniAssentiId = [];
          //Se sto qua dentro vuol dire che l'appello è stato fatto, quindi se manca un alunno lo segno come ND (Non definito)
          for (let i = 0; i < newAlunniArray.length; i++) {
            if (newAlunniArray[i].presenza === undefined) {
              newAlunniArray[i].presenza = { statoPresenza: "ND" };
              if (newAlunniArray[i].isRitirato !== 1 && newAlunniArray[i].isIscritto !== 0) //Senno fa il push anche dei ritirati
                alunniAssentiId.push(newAlunniArray[i].idAlunno);

            }
          }
          for (let presenza of arrayAppello) {
            if (presenza.stato_presenza === "A") {
              let indexAlunno = newAlunniArray.findIndex(a => a.id === presenza?.idAlunno);
              if (indexAlunno !== -1) {
                //Ho messo un controllo, se è non è ritirato lo conteggio
                if (newAlunniArray[indexAlunno]?.isRitirato === 0 && newAlunniArray[indexAlunno]?.isIscritto === 1) {
                  if (presenza.idAlunno !== undefined)
                    alunniAssentiId.push(presenza.idAlunno);
                }
              }
            }
          }

          if (presenzeValidate.length === newAlunniArray.length && presenzeValidate.length > 0)
            setAllValidate(true)
          else setAllValidate(false)
          setAlunniAssenti([...alunniAssentiId]);
          setStatoAppello(2);
        } else {
          //appello non fatto
          setStatoAppello(0);
        }

        getAssenzeDaGiustificare(newAlunniArray, dataRichiesta);

      })
      .catch((error) => {

      })
  }
  async function getAssenzeGiustificate(alunniTmp, dataInizio, dataFine) {

    let idAlunni = [];
    for (let alunno of alunniTmp) {
      idAlunni.push(alunno?.id)
    }

    let dataInizioConverted = DateTime.fromISO(dataInizio);
    let dataInizioSql = dataInizioConverted.toISODate();


    let dataFineConverted = DateTime.fromISO(dataFine);
    let dataFineSql = dataFineConverted.toISODate();

    let alunniArray = alunniTmp;

    let dataObj = {
      "dataDal": dataInizioSql,
      "dataAl": dataFineSql,
      "idAlunno": idAlunni
    }


    PresenzeAPI.getAssenzeGiustificate(dataObj)
      .then((result) => {

        if (result.alunni.length > 0) {
          for (let i = 0; i < result.alunni.length; i++) {
            let indexAlunno = alunniArray.findIndex(alunno => alunno.id === result?.alunni[i]?.idAlunno)
            if (indexAlunno !== -1) {
              let objGiustificata = {
                idAlunno: result.alunni[i]?.idAlunno,
                dataGiustificazione: result.alunni[i]?.dataGiustificazione,
                statoPresenza: result.alunni[i]?.dataGiustificazione,
              }
              alunniArray[indexAlunno].giustificate.push(objGiustificata)
            }
          }

        }

        if (!showBes) {
          setAlunni([...alunniArray]);
          setHasLoaded(true);

        } else {
          addBesToArray(alunni, arrayBes);
        }


      })
      .catch((error) => { })
  }

  async function confirmAppello() {
    let idAlunni = [];

    for (let alunno of alunni) {
      if (isAlunnoIscrittoInData(dataRichiesta, alunno)) {
        idAlunni.push({
          //"data": DateTime.fromSQL(dataRichiesta).toUTC().toISO(),
          data: dataRichiesta,
          idAlunno: alunno?.id,
          statoPresenza: alunniAssenti.includes(alunno?.id) ? 'A' : 'P',
          note: '',
          idUtenteInserimento: idDocente,
          idOrario: idOrarioFirma
        })
      }
    }


    let dataObj = {
      idCorso: idCorso,

      // "data": dataRichiesta,
      alunni: idAlunni
    }

    PresenzeAPI.inserisciPresenze(dataObj)
      .then((result) => {
        setStatoAppello(2);
        getRegistroDiClasse(dataRichiesta, idCorso, idMateria, idDocente, oreDiLezioneClasseSelezionata, idOrario, isConsultaRegistro)

        openAlertMsg("Appello confermato", "success")
      })
      .catch((error) => {
        if (error.response.status === 403) logout();
        else {
          openAlertMsg("Errore durante l'aggiunta dell'appello", "error")

        }
      })
  }
  async function modificaAppello() {
    let idAlunni = [];
    for (let alunno of alunni) {
      if (isAlunnoIscrittoInData(dataRichiesta, alunno)) {
        idAlunni.push({
          //"data": DateTime.fromSQL(dataRichiesta).toUTC().toISO(),
          data: dataRichiesta,
          idAlunno: alunno?.id,
          statoPresenza: alunno?.presenza?.statoPresenza != "ER" && alunno?.presenza?.statoPresenza != "UA" && alunno?.presenza?.statoPresenza != "ER/UA" ? (alunniAssenti.includes(alunno?.id) ? 'A' : 'P') : alunno?.presenza?.statoPresenza,
          note: '',
          idUtenteAggiornamento: idDocente
        })
      }
    }


    let dataObj = {
      idCorso: idCorso,
      alunni: idAlunni
    }


    PresenzeAPI.modificaPresenze(dataObj)
      .then((result) => {
        setStatoAppello(2);
        openAlertMsg("Appello modificato", "success")
        getRegistroDiClasse(dataRichiesta, idCorso, idMateria, idDocente, oreDiLezioneClasseSelezionata, idOrario, isConsultaRegistro)
      })
      .catch((error) => { })


  }
  async function checkOraFirmata(idOrarioFirma, idDocente, idCorso, idMateria) {
    let dataObj = {
      "idDocente": idDocente,
      "idOrario": idOrarioFirma
    }
    RegistroAPI.checkOraFirmata(dataObj)
      .then((result) => {
        setOraFirmata(result.check)
      })
      .catch((error) => { })
  }
  async function firmaOra() {
    let tipoOra = typeLezione === 0 ? "teoria" : "pratica";
    let dataObj = {

      pin: pinConfermaOra,
      idDocente: idDocente,
      idOrario: idOrarioFirma,
      idCorso: idCorso,
      dataLezione: dataRichiesta, //2022-10-04
      tipoOra: tipoOra,
      idMateria: idMateria
    }
    if (idOrarioFirma !== -1) {
      RegistroAPI.firmaOra(dataObj)
        .then((result) => {
          getPercentualeOreFirma(idOrarioFirma, idDocente, idCorso, idMateria);
          getRegistroDiClasse(dataRichiesta, idCorso, idMateria, idDocente, oreDiLezioneClasseSelezionata, idOrario, isConsultaRegistro)
          setOraFirmata(true);
          setShowFirmaOraDialog(false);
          openAlertMsg("Ora firmata", "success")
        })
        .catch((err) => {
          let errorMsg = "";
          if (err.response.status === 420) {
            errorMsg = err?.response?.data?.data[0]?.msg ?? "Pin errato";
          } else {
            errorMsg = "Pin errato";
          }
          openAlertMsg(errorMsg, "error")
        })
    } else {
      openAlertMsg("Nessuna lezione associata al docente", "error")
      setShowFirmaOraDialog(false);
    }
  }

  async function modificaFirmaOra() {
    let tipoOra = typeLezione === 0 ? "teoria" : "pratica";
    let dataObj = {
      dataLezione: dataRichiesta, //2022-10-04
      idDocente: idDocente,
      idOrario: idOrarioFirma,
      idCorso: idCorso,
      tipoOra: tipoOra,
      idMateria: idMateria

    }
    RegistroAPI.modificaFirmaOra(dataObj)
      .then((result) => {
        getRegistroDiClasse(dataRichiesta, idCorso, idMateria, idDocente, oreDiLezioneClasseSelezionata, idOrario, isConsultaRegistro)
        setOraFirmata(true);
        setShowFirmaOraDialog(false);
        openAlertMsg("Ora firma modificata", "success")
      })
      .catch((error) => {
        if (error.response.status === 403) logout();
        else openAlertMsg("Errore durante modifica della firma dell'ora", "error")
      })

  }

  async function getPermessiRE(idOrario, idCorso, data, isConsulta) { //Se puoi fare l'appello o firmare l'ora

    let dataObj = {
      idCorso: idCorso,
      idOrario: (idOrario !== undefined && idOrario != -1) ? idOrario : null,
      data: data,
      consulta: isConsulta
    }
    RegistroAPI.getPermessiRE(dataObj)
      .then((result) => {


        if (result?.showFirmaOra === true || result?.showModificaFirmaOra === true)
          setShowFirmaOra(true);
        else setShowFirmaOra(false);

        // if(dataRichiesta <= dataOggi) {
        setShowModificaAppello(result?.showModificaAppello ?? false);
        setShowIniziaAppello(result?.showIniziaAppello ?? false)

        if (result?.dataPeriodo?.inizio !== undefined)
          setDataInizioPeriodo(DateTime.fromISO(result?.dataPeriodo?.inizio))
        else setDataInizioPeriodo(null)

        if (result?.dataPeriodo?.fine !== undefined)
          setDataFinePeriodo(DateTime.fromISO(result?.dataPeriodo?.fine))
        else setDataFinePeriodo(null)

        setCanAddEvents(result?.canAddEvents ?? false)
        setCanAddEventsPostPeriodo(result?.canAddEventsPostPeriodo ?? false);

        if (result?.showIniziaAppello && !result?.showModificaAppello || result?.showIniziaAppello && result?.showModificaAppello)
          setStatoAppello(0)
        else if (!result?.showIniziaAppello && result?.showModificaAppello)
          setStatoAppello(2)
        // } else {
        //   setShowModificaAppello(false);
        //   setShowIniziaAppello(false)
        // }
      })
      .catch((error) => {
        setShowFirmaOra(false);
        setShowModificaAppello(false);
        setShowIniziaAppello(false)
      })

  }

  async function getPercentualeOreFirma(idOrario, idDocente, idCorso, idMateria) {
    let dataObj = {
      idOrario: idOrario,
      idDocente: idDocente,
      idCorso: idCorso,
      idMateria: idMateria
    }
    RegistroAPI.getPercentualeOreFirma(dataObj)
      .then((result) => {
        setOreFirmate(result)
      })
      .catch((error) => { })

  }


  return (
    <Fragment >
      {/* <Box sx={{ height: '5vh' }}></Box> */}

      <Box sx={{ px: 5, mt: 6 }}>
        {alunni.length > 0 || oreGiornata.length > 0 ? (
          <Box display={"flex"} flexDirection={{ xs: 'column', md: 'row' }} alignItems={{ xs: '', md: "start" }} justifyContent={"space-between"}>
            <Box display={"flex"} alignItems={"center"} flexDirection={{ xs: 'column', md: 'row' }} width={{ xs: '100%', md: 'unset' }} >
              {/* <Fragment> */}
              <Button disabled={(statoAppello === 1 || statoAppello === 3) || !hasLoaded} sx={{ width: { xs: '100%', md: 'unset' } }} onClick={goBackToVistaSintetica} variant="contained" startIcon={<ArrowBack />}>
                Torna alla vista sintetica
              </Button>
              {!isConsultaRegistro ? (
                <Button disabled={(statoAppello === 1 || statoAppello === 3) || !hasLoaded} sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} variant="outlined" onClick={besPinDialog}>
                  {showBes === true ? 'Bes visibili per ' : 'Mostra Bes '} <TimerBes startTimer={showBes} stopViewBes={stopViewBes} />
                </Button>) : null}
              <Button disabled={statoAppello === 1 || statoAppello === 3} startIcon={<Help />} sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} variant="outlined" color="secondary" onClick={openDialogLegenda}>
                Mostra legenda
              </Button>
              {/* </Fragment> */}

            </Box>
            {(showFirmaOra && hasDocenteFunctions && (idOrario == null || idOrario == -1)) && (
              <Button disabled={!hasLoaded} sx={{ mt: { xs: 2, md: 0 }, width: { xs: '100%', md: 'unset' } }} variant="contained" onClick={openElencoOreFirma} color="secondary" >
                Verifica firma orario
              </Button>
            )}
            {(showFirmaOra === true && hasDocenteFunctions && (idOrario != null && idOrario != -1)) ? (
              <Box display={"flex"} alignItems={"center"}>
                {/* <Box display={"flex"} alignItems={"center"} flexDirection={'row'} width={{ xs: '100%', md: 'unset' }} > */}
                {/* {!oraFirmata ? (
                    <Typography color="error" sx={{ mt: { xs: 2, md: 0 }, ml: { xs: 0, md: 2 }, mr: 1, display: 'flex', alignItems: 'center', textAlign: { xs: 'center', md: 'unset' }, width: { xs: '50%', md: 'unset' } }}>
                      <Warning sx={{ mr: 1 }} />Presenza non ancora firmata
                    </Typography>
                  ) :
                    <Typography sx={{ color: '#2e7d32', mt: { xs: 2, md: 0 }, ml: { xs: 0, md: 2 }, mr: 1, display: 'flex', alignItems: 'center', textAlign: { xs: 'center', md: 'unset' }, width: { xs: '50%', md: 'unset' } }}>
                      <CheckCircleOutline sx={{ mr: 1 }} />Presenza firmata
                    </Typography>
                  } */}
                <Box display={"flex"} alignItems={"center"} flexDirection={'column'} width={{ xs: '100%', md: 'unset' }}>

                  {!oraFirmata ? (
                    <Box display={"flex"} alignItems={"center"} flexDirection={'row'}>
                      <Typography color="error" sx={{ mt: { xs: 2, md: 0 }, ml: { xs: 0, md: 2 }, mr: 1, display: 'flex', alignItems: 'center', textAlign: { xs: 'center', md: 'unset' }, width: { xs: '100%', md: 'unset' } }}>
                        <Warning sx={{ mr: 1 }} />Presenza non ancora firmata
                      </Typography>
                      <Button disabled={statoAppello === 1 || statoAppello === 3} sx={{ mt: { xs: 2, md: 0 }, width: { xs: '100%', md: 'unset' } }} variant="contained" onClick={firmaOraPinDialog} color="error" >Conferma presenza</Button>
                    </Box>
                  ) : (
                    <Box display={"flex"} alignItems={"center"} flexDirection={'row'}>
                      <Typography sx={{ color: '#2e7d32', mt: { xs: 2, md: 0 }, ml: { xs: 0, md: 2 }, mr: 1, display: 'flex', alignItems: 'center', textAlign: { xs: 'center', md: 'unset' }, width: { xs: '100%', md: 'unset' } }}>
                        <CheckCircleOutline sx={{ mr: 1 }} />Presenza firmata
                      </Typography>
                      <Button disabled={statoAppello === 1 || statoAppello === 3} sx={{ mt: { xs: 2, md: 0 }, width: { xs: '100%', md: 'unset' } }} variant="outlined" onClick={firmaOraPinDialog} color="success" >Modifica tipologia di lezione</Button>
                    </Box>
                  )}
                  <Box display={"flex"} justifyContent={{ xs: 'center', md: 'end' }} flexDirection={'row'} width={'100%'}>
                    <Typography variant="caption" textAlign={"end"} sx={{ mt: 1, textAlign: 'end' }}>{oraSelezionata + "^ ora - " + nomeMateriaSelezionata}</Typography>
                  </Box>
                </Box>
              </Box>
            ) : null}
          </Box>
        ) : null
        }
      </Box >

      {
        hasLoaded ? (
          <Box sx={{

            mt: 3, mb: 3,
            width: '100%', // TODO: rimetterre al 100%
            maxHeight: { xs: 'unset', md: '75vh' }, display: "flex", justifyContent: 'center'
          }}>            {
              alunni.length > 0 || oreGiornata.length > 0 ? (
                <TableContainer sx={{ width: '95%', border: '5px solid green' }} component={Paper}>
                  <Table>
                    <TableHead >
                      <TableRow sx={{ backgroundColor: 'white' }}>
                        {/* TODO: test classe */}
                        <TableCell className='row-head' ref={refReset} style={{ minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2 }}>
                          <Typography color={'green'}
                            variant={mobile ? 'caption' : 'h6'}
                            sx={{ fontWeight: 'bold', textAlign: "center" }}>{(hasDocenteFunctions ? "Registro di Classe" : 'Consulta Registro di Classe').toUpperCase()}</Typography>
                          <Typography fontWeight={"bold"} textAlign={"center"}>{getDayMonthAndYearErrorDialog(dataRichiesta)}</Typography>
                        </TableCell>

                        {showIniziaAppello || showModificaAppello || !hasDocenteFunctions ? (
                          <TableCell
                            // className="colonna-nomi" 
                            className='row-head'
                            style={{ minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2 }}>
                            <Typography color={'green'}
                              variant={mobile ? 'caption' : 'body1'}
                              sx={{ fontWeight: 'bold', mr: 1, textAlign: 'center' }}>{(hasDocenteFunctions ? "Registro di Classe" : 'Consulta Registro di Classe').toUpperCase()}</Typography>
                          </TableCell>
                        ) : null}

                        {/* <TableCell className='row-head' style={{ minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2 }}>
                        </TableCell> */}
                        <TableCell className='row-head' style={{ minWidth: '100px', borderRight: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2 }}>
                          <Typography textAlign={"center"}>STATO</Typography>

                        </TableCell>
                        <TableCell className='row-head' style={{ minWidth: '100px', borderRight: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2 }}>
                          <Typography textAlign={"center"}>Alunni presenti:</Typography>
                          <Typography textAlign={"center"}><b>{alunniNonRitiratiNumber - alunniAssenti.length} (su {alunniNonRitiratiNumber})</b></Typography>
                        </TableCell>
                        {oreGiornata.map((ora, index) => (
                          <TableCell className='row-head' key={ora.id} ref={(element) => ref.current[index] = element} sx={{ backgroundColor: 'white', p: 2, minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)' }} > {/*ref={index === oreGiornata.length - 1 ? lastColumnRef : null} */}
                            <Typography variant="subtitle2" color={"primary"} textAlign={"center"}>{ora?.ora + "^ ORA"}</Typography>
                            <Typography textAlign={"center"}><b>{ora?.materia?.nomeMateriaBreve}</b></Typography>
                            <Typography variant="subtitle2" textAlign={"center"}>{"(" + ora?.oraInizio + " - " + ora?.oraFine + ")"}</Typography>
                            {ora?.firma?.isFirmata ? (
                              <Fragment>
                                <Typography variant="subtitle2" textAlign={"center"} color="primary">PRESENZA FIRMATA</Typography>
                                <Typography variant="subtitle2" textAlign={"center"} color="primary">{"(" + ora?.firma?.tipoOra + ")"}</Typography>
                              </Fragment>) :
                              <Typography variant="subtitle2" textAlign={"center"} >PRESENZA NON FIRMATA</Typography>
                            }
                          </TableCell>
                        ))}

                      </TableRow>
                    </TableHead>

                    <TableBody>
                      <TableRow key={0}>
                        {showIniziaAppello || showModificaAppello || !hasDocenteFunctions ? (
                          <TableCell >
                            {!allValidate ? (
                              <Box px={5} >
                                {/* <Typography textAlign={"center"}>Alunni presenti:</Typography> */}
                                {/* <Typography textAlign={"center"}><b>{alunni.length - alunniAssenti.length} (su {alunni.length})</b></Typography> */}
                                {showIniziaAppello ? (
                                  <Fragment>
                                    {statoAppello === 0 && (
                                      <Button size="small" onClick={iniziaAppello} sx={{ mt: 2 }} color="primary" variant="contained" >
                                        <Typography variant={mobile ? "caption" : ''} textAlign={"center"}>Inizia appello</Typography>
                                      </Button>
                                    )}
                                    {statoAppello === 1 && (
                                      <Fragment>
                                        <Button size="small" onClick={confermaPresenze} disabled={btnConfermaAppelloDisabled} sx={{ mt: 2 }} color="success" variant="contained" >
                                          <Typography variant={mobile ? "caption" : ''} textAlign={"center"}>Conferma appello</Typography>
                                        </Button>
                                        <Button size="small" onClick={assenzaDiMassa} disabled={btnConfermaAppelloDisabled} sx={{ mt: 1 }} color="info" variant="outlined">
                                          <Typography variant={mobile ? "caption" : ''} textAlign={"center"}>Assenza di massa</Typography>
                                        </Button>
                                      </Fragment>
                                    )}
                                  </Fragment>
                                ) : null}
                                {showModificaAppello ? (
                                  <Fragment>
                                    {statoAppello === 2 && (
                                      <Button onClick={modificaPresenze} size="small" sx={{ mt: 2 }} color="info" variant="contained">
                                        <Typography variant={mobile ? "caption" : ''} textAlign={"center"}>Modifica appello</Typography>
                                      </Button>
                                    )}
                                    {statoAppello === 3 && (
                                      <Fragment>
                                        <Button size="small" onClick={confermaModifiche} disabled={btnConfermaAppelloDisabled} sx={{ mt: 2 }} color="success" variant="contained">
                                          <Typography variant={mobile ? "caption" : ''} textAlign={"center"}>Conferma modifiche</Typography>
                                        </Button>
                                        <Button size="small" onClick={annullaModifiche} disabled={btnConfermaAppelloDisabled} sx={{ mt: 1 }} color="error" variant="outlined">
                                          <Typography variant={mobile ? "caption" : ''} textAlign={"center"}>Annulla modifiche</Typography>
                                        </Button>
                                      </Fragment>
                                    )}
                                  </Fragment>
                                ) : null}
                              </Box>
                            ) : <Typography>Tutte le presenze sono state validate, non è più possibile modificare l'appello.</Typography>}
                          </TableCell>
                        ) : null}
                        <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', minWidth: nomeClasse.length > 50 ? '400px' : '300px' }}>
                          <Box className="spacebetween-direction" sx={{ backgroundColor: 'white', p: 2 }}>
                            <Box sx={{ backgroundColor: 'white', p: 2, display: 'flex', flexDirection: 'column' }}>
                              <Typography variant="caption">Corso:</Typography>
                              <Typography color={'primary'}
                                variant={mobile ? 'caption' : 'body1'}
                                sx={{ fontWeight: 'bold', mr: 1 }}>{(codiceCorso + " - " + nomeClasse + " (" + codiceSimon + ")" ?? "").toUpperCase()}</Typography>
                              <Typography variant="caption" sx={{ mt: 1 }}>Sede:</Typography>
                              <Typography
                                variant={mobile ? 'caption' : 'subtitle2'}
                                sx={{ fontWeight: 'bold', mr: 1 }}>{sedeUtente !== '' ? sedeUtente : ''}</Typography>
                            </Box>
                            {(hasDocenteFunctions && (canAddEvents || canAddEventsPostPeriodo)) ? (
                              <IconButton disabled={statoAppello === 1 || statoAppello === 3} onClick={() => openAggiuntaEvento(null, true)}>
                                <AddCircle />
                              </IconButton>
                            ) : null}
                          </Box>
                        </TableCell>
                        <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                          <Box display="flex" justifyContent={"center"}>

                            {comunicazioniClasse.map((comunicazione) => (
                              <Fragment key={comunicazione?.id}>
                                <EventoChip dataInizioPeriodo={dataInizioPeriodo} dataFinePeriodo={dataFinePeriodo} statoAppello={statoAppello} idDocente={idDocente} tipoRegistro={"classe"} eventoAggiunto={eventoAggiunto} getRegistro={getRegistro} permessiUtente={permessiUtente} oreRegistro={oreGiornata} objComunicazione={comunicazione} alunno={null} listAlunni={alunni} />
                              </Fragment>
                            ))}
                          </Box>
                        </TableCell>
                        <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                        </TableCell>
                        {oreGiornata.map((ora) => (
                          <TableCell key={ora?.id} style={{ minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                            {ora?.comunicazioni.length > 0 ? (
                              <Box justifyContent={"center"} display="flex">
                                {ora?.comunicazioni.map((comunicazioneClasse) => (
                                  <EventoChip dataInizioPeriodo={dataInizioPeriodo} dataFinePeriodo={dataFinePeriodo} statoAppello={statoAppello} idDocente={idDocente} tipoRegistro={"classe"} eventoAggiunto={eventoAggiunto} getRegistro={getRegistro} permessiUtente={permessiUtente} key={comunicazioneClasse?.id} oreRegistro={oreGiornata} objComunicazione={comunicazioneClasse} listAlunni={alunni} />
                                ))}
                              </Box>
                            ) : null}
                          </TableCell>))}
                      </TableRow>
                      {alunni.map((alunno, index) => (
                        <TableRow style={{ height: '100px' }} key={alunno?.id} >
                          {showIniziaAppello || showModificaAppello || !hasDocenteFunctions ? (
                            <TableCell
                              className={isAlunnoIscrittoInData(dataRichiesta, alunno) === false ? 'disabledCell' : ""}
                              style={{ width: '30px', borderRight: '1px solid rgba(224, 224, 224, 1)', }}
                            >

                              {isAlunnoIscrittoInData(dataRichiesta, alunno) == true ? (
                                <Box display="flex" flexDirection={"column"} alignItems={"center"}>

                                  <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography color={alunniAssenti.includes(alunno?.id) ? 'error' : 'black'}>A</Typography>
                                    {statoAppello === 0 ? (
                                      <Switch
                                        checked={!alunniAssenti.includes(alunno?.id)} disabled={(statoAppello !== 1 && statoAppello !== 3) || alunno?.presenza?.validata === true || alunno?.presenza?.orario_uscita_reale != null || alunno?.presenza?.orario_ingresso_reale != null} onChange={(e) => { selectPresenza(e, alunno?.id) }} label="" />
                                    ) : (
                                      <Box >
                                        {alunno?.presenza?.statoPresenza !== "ND" || alunno?.presenza === undefined ? (
                                          <TogglePresenza sx={{ m: 1 }}
                                            checked={!alunniAssenti.includes(alunno?.id)} disabled={(statoAppello !== 1 && statoAppello !== 3) || alunno?.presenza?.validata === true || alunno?.presenza?.orario_uscita_reale != null || alunno?.presenza?.orario_ingresso_reale != null} onChange={(e) => { selectPresenza(e, alunno?.id) }} label="" />
                                        ) : (
                                          <Switch
                                            checked={!alunniAssenti.includes(alunno?.id)} disabled={(statoAppello !== 1 && statoAppello !== 3) || alunno?.presenza?.validata === true || alunno?.presenza?.orario_uscita_reale != null || alunno?.presenza?.orario_ingresso_reale != null} onChange={(e) => { selectPresenza(e, alunno?.id) }} label="" />
                                        )}
                                      </Box>
                                    )}
                                    {statoAppello === 0 ?
                                      (
                                        <Typography>P</Typography>
                                      ) : (
                                        <Typography color={(alunniAssenti.includes(alunno?.id) || alunno?.presenza?.statoPresenza === "ND") ? 'black' : 'green'}>P</Typography>
                                      )
                                    }
                                  </Stack>
                                  <Typography variant="subtitle2">{alunno?.presenza?.validata === true && statoAppello == 3 ? "Validata (non modificabile)" : null}</Typography>
                                </Box>
                              ) : null}
                            </TableCell>
                          ) : null}
                          <TableCell
                            className={isAlunnoIscrittoInData(dataRichiesta, alunno) === false ? 'disabledCell colonna-nomi' : "colonna-nomi"}
                            sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                            <Box className="spacebetween-direction" sx={{ backgroundColor: isAlunnoIscrittoInData(dataRichiesta, alunno) ? 'white' : '#eee', px: 2 }}>
                              <Box display={"flex"} alignItems={"center"}>
                                <Typography sx={{ mr: 2 }}>  {(index + 1) + ")"}</Typography>
                                <Typography variant={mobile ? 'caption' : 'body1'} onClick={() => vediDettaglioAlunno(alunno?.id, 0, alunno?.nome, alunno?.cognome, false, alunno?.besObj)} sx={{ cursor: (statoAppello !== 1 && statoAppello !== 3) ? 'pointer' : 'default', mr: 2, fontWeight: alunno?.isMaggiorenne ? 'bold' : 'unset' }}>
                                  {(alunno?.cognome).toUpperCase() + " " + (alunno?.nome).toUpperCase()}
                                </Typography>
                              </Box>
                              {isAlunnoIscrittoInData(dataRichiesta, alunno) === true ? (
                                <Box display={"flex"} alignItems={"center"}>
                                  {(hasDocenteFunctions && (canAddEvents || canAddEventsPostPeriodo)) ? (
                                    <IconButton disabled={statoAppello === 1 || statoAppello === 3} onClick={() => openAggiuntaEvento(alunno, false)}>
                                      <AddCircle />
                                    </IconButton>
                                  ) : null}
                                  {showBes && alunno?.hasBes ? (
                                    <Box width={"10px"} height={"10px"} sx={{ cursor: (statoAppello !== 1 && statoAppello !== 3) ? 'pointer' : 'default', p: 1, mr: 1, borderRadius: '100%', border: '1px solid ' + alunno?.besObj?.colorBes, display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#1976d2', ml: 1 }}   >
                                      <AccessibilityNew sx={{ color: alunno?.besObj?.colorBes }} onClick={() => vediDettaglioAlunno(alunno?.id, 4, alunno?.nome, alunno?.cognome, false, alunno?.besObj)} />
                                    </Box>
                                  ) : null}
                                </Box>
                              ) : null}
                            </Box>
                            {alunno?.msgIscrizione != null ? (
                              <Box sx={{ px: 2 }}>
                                <Typography color="primary" variant="subtitle2">{alunno?.msgIscrizione}</Typography>
                              </Box>
                            ) : null}
                          </TableCell>
                          <TableCell className={isAlunnoIscrittoInData(dataRichiesta, alunno) === false ? 'disabledCell' : ""} sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                            <Box display="flex" justifyContent={"center"}>
                              {canShowADG(alunno) ? (
                                <Box onClick={() => vediDettaglioAlunno(alunno?.id, 1, alunno?.nome, alunno?.cognome, false, alunno?.besObj)} width={"30px"} height={"30px"} backgroundColor={checkGravitaAssenza(alunno?.assenza?.data)} display={"flex"} alignItems={"center"} justifyContent={"center"}
                                  sx={{ cursor: (statoAppello !== 1 && statoAppello !== 3) ? 'pointer' : 'default', mx: 1, p: 1, borderRadius: '100%', color: 'white' }}>
                                  <Typography fontWeight={"bold"} >AdG</Typography>
                                </Box>
                              ) : null}
                              {(alunno?.giustificate !== undefined && alunno?.giustificate.length > 0) && (
                                <Box width={"30px"} height={"30px"} onClick={() => vediDettaglioAlunno(alunno?.id, 1, alunno?.nome, alunno?.cognome, false, alunno?.besObj)}
                                  backgroundColor={"green"} border={"1px solid green"}
                                  display={"flex"} alignItems={"center"} justifyContent={"center"}
                                  sx={{ mx: 1, p: 1, borderRadius: '100%', color: 'white', cursor: (statoAppello !== 1 && statoAppello !== 3) ? 'pointer' : 'default', }}>

                                  <Typography fontWeight={"bold"} color={"success"} >G</Typography>
                                </Box>
                              )}
                              {alunno?.comunicazioni.length > 0 ? (
                                alunno?.comunicazioni.map((comunicazione) => (
                                  <Fragment key={alunno?.id + comunicazione?.id}>
                                    {comunicazione?.indexColonna == null ? (
                                      <EventoChip dataInizioPeriodo={dataInizioPeriodo} dataFinePeriodo={dataFinePeriodo} statoAppello={statoAppello} idDocente={idDocente} tipoRegistro={"classe"} eventoAggiunto={eventoAggiunto} getRegistro={getRegistro} permessiUtente={permessiUtente} oreRegistro={oreGiornata} objComunicazione={comunicazione} alunno={alunno} listAlunni={alunni} />
                                    ) : null}
                                  </Fragment>
                                ))
                              ) : null}
                            </Box>
                          </TableCell>

                          <TableCell className={isAlunnoIscrittoInData(dataRichiesta, alunno) === false ? 'disabledCell' : ""} sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                            {isAlunnoIscrittoInData(dataRichiesta, alunno) == true ? (
                              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: (alunno?.presenza?.statoPresenza !== "P" && alunno?.presenza?.statoPresenza !== "ND" && alunno?.presenza?.statoPresenza !== "A" && statoAppello != 1 && statoAppello != 3) ? 'pointer' : 'default' }}
                                onClick={() => alunno?.presenza?.statoPresenza !== "P" && alunno?.presenza?.statoPresenza !== "ND" && alunno?.presenza?.statoPresenza !== "A" ? vediDettaglioAlunno(alunno?.id, 2, alunno?.nome, alunno?.cognome, false, undefined, alunno?.presenza) : null}
                              >
                                <Presenza alunno={alunno} isRegistroDettaglio={true} />
                              </Box>
                            ) : null}
                          </TableCell>
                          {oreGiornata.map((ora, indexOra) => (
                            <TableCell className={isAlunnoIscrittoInData(dataRichiesta, alunno) === false ? 'disabledCell' : ""} key={ora?.id + alunno?.id} style={{ minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                              <Box justifyContent={"center"} display="flex">
                                {alunno?.comunicazioni.length > 0 ? (
                                  alunno?.comunicazioni.map((comunicazione) => (
                                    <Fragment key={alunno?.id + comunicazione?.id}>
                                      {comunicazione?.indexColonna === indexOra ? (
                                        <EventoChip dataInizioPeriodo={dataInizioPeriodo} dataFinePeriodo={dataFinePeriodo} statoAppello={statoAppello} idDocente={idDocente} tipoRegistro={"classe"} eventoAggiunto={eventoAggiunto} getRegistro={getRegistro} permessiUtente={permessiUtente} oreRegistro={oreGiornata} objComunicazione={comunicazione} alunno={alunno} listAlunni={alunni} />
                                      ) : null}
                                    </Fragment>
                                  ))
                                ) : null}
                              </Box>
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>

                  </Table>
                </TableContainer>
              ) : (
                <Box height={"70vh"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                  <Typography variant="h5">Per la data selezionata (<b>{(getDayMonthAndYearErrorDialog(dataRichiesta))}</b>) non è possibile visualizzare il registro giornaliero</Typography>
                  <Button sx={{ mt: 2 }} onClick={goBackToVistaSintetica} variant="contained" startIcon={<ArrowBack />}>
                    Torna alla vista sintetica
                  </Button>
                </Box>
              )
            }
          </Box >
        ) : (
          <Box className="centered-column-direction" sx={{ minHeight: '80vh' }}>
            <CircularProgress />
            <Typography variant="body2" sx={{ mt: 3 }}>Caricamento registro...</Typography>
          </Box>
        )
      }

      <Dialog hideBackdrop open={showBesPinDialog}>
        <DialogTitle>Inserimento codice Bes</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Dopo l'inserimento del codice di conferma, sarà possibile visualizzare i bes degli studenti per <b>5 minuti</b>,
            dopodiché sarà necessario ripetere l'operazione di inserimento del codice.
          </Typography>
          <Box display={"flex"} justifyContent={"center"} sx={{ my: 5 }}>
            <FormControl sx={{ width: '50%' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Pin di conferma</InputLabel>
              <OutlinedInput type={showPassword ? 'text' : 'password'}
                inputRef={refPinConfermaBes}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={showPasswordInput}  >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                onChange={changePinConfermaBesLength}
                // onBlur={changePinConfermaBes}
                label="Pin di conferma"
              />
            </FormControl>
          </Box>
          <Box display={"flex"} justifyContent={"end"}>
            <Button variant="outlined" color="error" onClick={besPinDialog}>Annulla</Button>
            <Button sx={{ ml: 1, px: 5 }}
              // disabled={!valuePinConfermaBes} //TODO: 
              disabled={pinBesTmp.length === 0}
              variant="contained" color="success" onClick={confirmBesPin}>Conferma</Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog hideBackdrop open={showFirmaOraDialog}>
        <DialogTitle>{!oraFirmata ? "Conferma presenza" : 'Modifica tipologia di lezione'}</DialogTitle>
        <DialogContent>
          <Typography variant="body1" >
            {!oraFirmata ? 'Per poter firmare la presenza ' : 'La presenza è stata firmata '} <b>({oraSelezionata}^ora - {nomeMateriaSelezionata}, del {getDayMonthAndYearErrorDialog(dataRichiesta)})</b>{!oraFirmata ? 'è necessario inserire il pin di conferma e selezionare la tipologia di lezione' : null}.
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Typography><b>Ore totali:</b> {oreFirmate?.oreAssegnate ?? 0} </Typography>
          <Typography><b>Ore  teoria</b> (firmate): {oreFirmate?.teoria?.numeroOre ?? 0} - {(oreFirmate?.teoria?.percentuale ?? 0) + "%"}</Typography>
          <Typography sx={{ mb: 2 }}><b>Ore pratica</b> (firmate): {oreFirmate?.pratica?.numeroOre ?? 0} - {(oreFirmate?.pratica?.percentuale ?? 0) + "%"}</Typography>

          {(modificaMode || !oraFirmata) && (<Box display={"flex"} justifyContent={"center"} sx={{ mt: 5, mb: 2 }}>
            <FormControl sx={{ width: '50%' }}>
              <InputLabel id="typeLezione-label">
                Tipologia di lezione
              </InputLabel>
              <Select
                data-testid='selTypeLezioneSelect'
                labelId="typeLezione-label"
                label='Tipologia di lezione '
                value={typeLezione}
                onChange={changeTypeLezione}
              >
                <MenuItem key={-1} value={-1} >

                </MenuItem>
                <MenuItem key={0} value={0} >
                  Teorico
                </MenuItem>
                <MenuItem key={1} value={1} >
                  Pratico
                </MenuItem>
              </Select>
            </FormControl>
          </Box>)}
          {!oraFirmata && (<Box display={"flex"} justifyContent={"center"} sx={{ mt: 2, mb: 5 }}>
            <FormControl sx={{ width: '50%' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Pin di conferma</InputLabel>
              <OutlinedInput type={showPasswordPinOra ? 'text' : 'password'}
                inputRef={refPinConfermaOra}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={showPasswordInputPin}  >
                      {showPasswordPinOra ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                onChange={changePinConfermaOraLength}
                // onBlur={changePinConfermaOra}
                label="Pin di conferma"
              />
            </FormControl>
          </Box>)}
          <Box display={"flex"} justifyContent={"end"}>

            {oraFirmata ? (
              <Box sx={{ mt: 1 }}>
                {!modificaMode ? (
                  <Button onClick={firmaOraPinDialog} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} color="error" variant="outlined">chiudi dettaglio</Button>
                ) : (<Button onClick={firmaOraPinDialog} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} color="error" variant="outlined">Annulla modifiche</Button>)}
                {!modificaMode ? (<Button onClick={() => setModificaMode(true)}
                  sx={{ ml: { xs: 0, md: 2 }, width: { xs: '100%', md: 'fit-content' } }}
                  variant="contained" >
                  Modifica tipologia di lezione
                </Button>) : (<Button onClick={() => modificaFirmaOra()} disabled={(typeLezione === -1) ? true : false} sx={{ ml: { xs: 0, md: 2 }, width: { xs: '100%', md: 'fit-content' } }}
                  color="success" variant="contained" >
                  Conferma Modifica
                </Button>)}
              </Box>)
              :
              (
                <Box>
                  <Button variant="outlined" color="error" onClick={firmaOraPinDialog}>Annulla</Button>
                  <Button sx={{ ml: 1, px: 5 }} variant="contained"
                    disabled={(typeLezione === -1 || pinConfermaOra === '') ? true : false}
                    // disabled={(typeLezione === -1 || !valuePinConfermaOra) ? true : false} //TODO: 
                    color="success" onClick={confirmOraPin}>Conferma</Button>
                </Box>
              )
            }
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog sx={{ marginTop: '7vh' }} fullWidth maxWidth="xl" open={dialogLegendaOpen}>
        <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"} >
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Legenda
          </Typography>
          <IconButton onClick={closeDialogLegenda} sx={{ ml: 5 }}  >
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Legende />
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button onClick={closeDialogLegenda} variant="contained" fullWidth color="primary">Chiudi finestra</Button>
        </DialogActions>
      </Dialog>

      <Dialog sx={{ marginTop: '7vh' }} fullWidth maxWidth="xl" open={dettaglioAlunnoOpen} onClose={closeDettaglioAlunno}>
        <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={"center"}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Dettaglio alunno ({nomeAlunnoSelezionato})</Typography>
          <IconButton onClick={closeDettaglioAlunno} sx={{ ml: 5 }}  >
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ pt: 0 }}>
          <DettaglioAlunno presenzaObj={presenzaObj} dontCallBesBE={true} canTouchAssenze={!hasDocenteFunctions} comesFromConsultaRegistro={!hasDocenteFunctions} besData={besObjAlunnoSelezionato} showBes={showBes} permessiUtente={permessiUtente} ruoliUtente={ruoliUtente} tabIndex={indexTabToOpen} idDocente={idDocente} id={idAlunnoSelezionato} idCorso={idCorso} dataAttuale={dataRichiesta}
            nomeAlunno={nomeAlunnoSelezionato} ritardo={openDialogRitardo} closeDettaglioAlunno={closeDettaglioAlunno} openAlertMsg={openAlertMsg} tipo={tipo} tipoRegistro={'classe'} />
        </DialogContent>
      </Dialog>

      <Dialog sx={{ marginTop: '7vh' }} open={aggiuntaEventoOpen} fullWidth maxWidth="lg" onClose={closeAggiuntaEvento}>
        <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"} sx={{ m: 0, p: 2 }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {addEventoTuttaClasse ? "Azioni di classe" : "Azioni studente"}
          </Typography>
          <IconButton onClick={closeAggiuntaEvento} sx={{ ml: 5 }}  >
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <AggiuntaEventi canAddEventsPostPeriodo={canAddEventsPostPeriodo} dataInizioPeriodo={dataInizioPeriodo} dataFinePeriodo={dataFinePeriodo} isTuttaClasse={addEventoTuttaClasse} dataRegistro={dataRichiesta} permessiUtente={permessiUtente} nomeClasse={nomeClasse} idDocente={idDocente} eventoAggiunto={eventoAggiunto} closeDialog={closeAggiuntaEvento} idCorso={idCorso} idMateria={idMateria}
            listAlunni={alunni} alunniSelezionati={alunniSelezionati} tipoRegistro={"classe"} logout={logout} />
        </DialogContent>
      </Dialog>

      {/* TODO: dialog temporanea per appello */}

      <Dialog sx={{ marginTop: '7vh' }} fullWidth
        maxWidth="xl"
        open={dialogAppelloIsOpen}
      // onClose={closeDialogAppello}
      >
        <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={"center"}>
          <span>Appello</span>
          {/* <IconButton onClick={closeDialogAppello} sx={{ ml: 5 }}  >
            <Close />
          </IconButton> */}
        </DialogTitle>
        <DialogContent>
          {
            hasLoaded ? (
              <Box sx={{ mt: 3, mb: 3, width: '100%', maxHeight: { xs: 'unset', md: '60vh' }, display: "flex", justifyContent: 'center' }} >
                {
                  alunni.length > 0 || oreGiornata.length > 0 ? (
                    <TableContainer sx={{ width: '95%' }} component={Paper}>
                      <Table>
                        <TableHead >
                          <TableRow sx={{ backgroundColor: 'white' }}>
                            <TableCell className='row-head' ref={refReset} style={{ minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2 }}>
                              <Typography fontWeight={"bold"} textAlign={"center"}>{getDayMonthAndYearErrorDialog(dataRichiesta)}</Typography>
                            </TableCell>

                            {showIniziaAppello || showModificaAppello || !hasDocenteFunctions ? (
                              <TableCell
                                // className="colonna-nomi" 
                                className='row-head'
                                style={{ minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2 }}>

                              </TableCell>
                            ) : null}

                            {/* <TableCell className='row-head' style={{ minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2 }}>
                        </TableCell> */}
                            {/* <TableCell className='row-head' style={{ minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2 }}>
                              <Typography textAlign={"center"}>STATO</Typography>

                            </TableCell> */}
                            <TableCell className='row-head' style={{ minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2 }}>
                              <Typography textAlign={"center"}>Alunni presenti:</Typography>
                              <Typography textAlign={"center"}><b>{alunniNonRitiratiNumber - alunniAssenti.length} (su {alunniNonRitiratiNumber})</b></Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          <TableRow key={0}>
                            {showIniziaAppello || showModificaAppello || !hasDocenteFunctions ? (
                              <TableCell >
                                {!allValidate ? (
                                  <Box px={5} >
                                    {/* <Typography textAlign={"center"}>Alunni presenti:</Typography> */}
                                    {/* <Typography textAlign={"center"}><b>{alunni.length - alunniAssenti.length} (su {alunni.length})</b></Typography> */}
                                    {showIniziaAppello ? (
                                      <Fragment>
                                        {statoAppello === 0 && (
                                          <Button size="small" onClick={iniziaAppello} sx={{ mt: 2 }} color="primary" variant="contained" >
                                            <Typography variant={mobile ? "caption" : ''} textAlign={"center"}>Inizia appello</Typography>
                                          </Button>
                                        )}
                                        {statoAppello === 1 && (
                                          <Fragment>
                                            <Button size="small" onClick={confermaPresenze} disabled={btnConfermaAppelloDisabled} sx={{ mt: 2 }} color="success" variant="contained" >
                                              <Typography variant={mobile ? "caption" : ''} textAlign={"center"}>Conferma appello</Typography>
                                            </Button>
                                            <Button size="small" onClick={assenzaDiMassa} disabled={btnConfermaAppelloDisabled} sx={{ mt: 1 }} color="info" variant="outlined">
                                              <Typography variant={mobile ? "caption" : ''} textAlign={"center"}>Assenza di massa</Typography>
                                            </Button>
                                          </Fragment>
                                        )}
                                      </Fragment>
                                    ) : null}
                                    {showModificaAppello ? (
                                      <Fragment>
                                        {statoAppello === 2 && (
                                          <Button onClick={modificaPresenze} size="small" sx={{ mt: 2 }} color="info" variant="contained">
                                            <Typography variant={mobile ? "caption" : ''} textAlign={"center"}>Modifica appello</Typography>
                                          </Button>
                                        )}
                                        {statoAppello === 3 && (
                                          <Fragment>
                                            <Button size="small" onClick={confermaModifiche} disabled={btnConfermaAppelloDisabled} sx={{ mt: 2 }} color="success" variant="contained">
                                              <Typography variant={mobile ? "caption" : ''} textAlign={"center"}>Conferma modifiche</Typography>
                                            </Button>
                                            <Button size="small" onClick={annullaModifiche} disabled={btnConfermaAppelloDisabled} sx={{ mt: 1 }} color="error" variant="outlined">
                                              <Typography variant={mobile ? "caption" : ''} textAlign={"center"}>Annulla modifiche</Typography>
                                            </Button>
                                          </Fragment>
                                        )}
                                      </Fragment>
                                    ) : null}
                                  </Box>
                                ) : <Typography>Tutte le presenze sono state validate, non è più possibile modificare l'appello.</Typography>}
                              </TableCell>
                            ) : null}
                            <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', minWidth: nomeClasse.length > 50 ? '400px' : '300px' }}>
                              <Box className="spacebetween-direction" sx={{ backgroundColor: 'white', p: 2 }}>
                                <Box sx={{ backgroundColor: 'white', p: 2, display: 'flex', flexDirection: 'column' }}>
                                  <Typography variant="caption" sx={{ mt: 1 }}>Corso:</Typography>
                                  <Typography color={'primary'}
                                    variant={mobile ? 'caption' : 'body1'}
                                    sx={{ fontWeight: 'bold', mr: 1 }}>{(codiceCorso + " - " + nomeClasse + " (" + codiceSimon + ")" ?? "").toUpperCase()}</Typography>
                                  <Typography variant="caption" sx={{ mt: 1 }}>Sede:</Typography>
                                  <Typography
                                    variant={mobile ? 'caption' : 'subtitle2'}
                                    sx={{ fontWeight: 'bold', mr: 1 }}>{sedeUtente !== '' ? sedeUtente : ''}</Typography>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                            </TableCell>
                          </TableRow>
                          {alunni.map((alunno, index) => (
                            <TableRow style={{ height: '100px' }} key={alunno?.id} >
                              {showIniziaAppello || showModificaAppello || !hasDocenteFunctions ? (
                                <TableCell
                                  className={isAlunnoIscrittoInData(dataRichiesta, alunno) === false ? 'disabledCell' : ""}
                                  style={{ width: '30px', borderRight: '1px solid rgba(224, 224, 224, 1)', }}
                                >

                                  {isAlunnoIscrittoInData(dataRichiesta, alunno) == true ? (
                                    <Box display="flex" flexDirection={"column"} alignItems={"center"}>

                                      <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography color={alunniAssenti.includes(alunno?.id) ? 'error' : 'black'}>A</Typography>
                                        {statoAppello === 0 ? (
                                          <Switch
                                            checked={!alunniAssenti.includes(alunno?.id)} disabled={(statoAppello !== 1 && statoAppello !== 3) || alunno?.presenza?.validata === true || alunno?.presenza?.orario_uscita_reale != null || alunno?.presenza?.orario_ingresso_reale != null} onChange={(e) => { selectPresenza(e, alunno?.id) }} label="" />
                                        ) : (
                                          <Box >
                                            {alunno?.presenza?.statoPresenza !== "ND" || alunno?.presenza === undefined ? (
                                              <TogglePresenza sx={{ m: 1 }}
                                                checked={!alunniAssenti.includes(alunno?.id)} disabled={(statoAppello !== 1 && statoAppello !== 3) || alunno?.presenza?.validata === true || alunno?.presenza?.orario_uscita_reale != null || alunno?.presenza?.orario_ingresso_reale != null} onChange={(e) => { selectPresenza(e, alunno?.id) }} label="" />
                                            ) : (
                                              <Switch
                                                checked={!alunniAssenti.includes(alunno?.id)} disabled={(statoAppello !== 1 && statoAppello !== 3) || alunno?.presenza?.validata === true || alunno?.presenza?.orario_uscita_reale != null || alunno?.presenza?.orario_ingresso_reale != null} onChange={(e) => { selectPresenza(e, alunno?.id) }} label="" />
                                            )}
                                          </Box>
                                        )}
                                        {statoAppello === 0 ?
                                          (
                                            <Typography>P</Typography>
                                          ) : (
                                            <Typography color={(alunniAssenti.includes(alunno?.id) || alunno?.presenza?.statoPresenza === "ND") ? 'black' : 'green'}>P</Typography>
                                          )
                                        }
                                      </Stack>
                                      <Typography variant="subtitle2">{alunno?.presenza?.validata === true && statoAppello == 3 ? "Validata (non modificabile)" : null}</Typography>
                                    </Box>
                                  ) : null}
                                </TableCell>
                              ) : null}
                              <TableCell
                                className={isAlunnoIscrittoInData(dataRichiesta, alunno) === false ? 'disabledCell colonna-nomi' : "colonna-nomi"}
                                sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                <Box className="spacebetween-direction" sx={{ backgroundColor: isAlunnoIscrittoInData(dataRichiesta, alunno) ? 'white' : '#eee', px: 2 }}>
                                  <Box display={"flex"} alignItems={"center"}>
                                    <Typography sx={{ mr: 2 }}>  {(index + 1) + ")"}</Typography>
                                    <Typography variant={mobile ? 'caption' : 'body1'} sx={{ mr: 2, fontWeight: alunno?.isMaggiorenne ? 'bold' : 'unset' }}>
                                      {(alunno?.cognome).toUpperCase() + " " + (alunno?.nome).toUpperCase()}
                                    </Typography>
                                  </Box>

                                  {/* {isAlunnoIscrittoInData(dataRichiesta, alunno) === true ? (
                                    <Box display={"flex"} alignItems={"center"}>
                                      {hasDocenteFunctions ? (
                                        <IconButton onClick={() => openAggiuntaEvento(alunno, false)}>
                                          <AddCircle />
                                        </IconButton>
                                      ) : null}
                                      {showBes && alunno?.hasBes ? (
                                        <Box width={"10px"} height={"10px"} sx={{ cursor: 'pointer', p: 1, mr: 1, borderRadius: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#1976d2', ml: 1 }}   >
                                          <AccessibilityNew onClick={() => vediDettaglioAlunno(alunno?.id, 3, alunno?.nome, alunno?.cognome, false, alunno?.besObj)} />
                                        </Box>
                                      ) : null}
                                    </Box>
                                  ) : null} */}
                                </Box>
                                {alunno?.msgIscrizione != null ? (
                                  <Box sx={{ px: 2 }}>
                                    <Typography color="primary" variant="subtitle2">{alunno?.msgIscrizione}</Typography>
                                  </Box>
                                ) : null}
                              </TableCell>
                              <TableCell className={isAlunnoIscrittoInData(dataRichiesta, alunno) === false ? 'disabledCell' : ""} sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                {isAlunnoIscrittoInData(dataRichiesta, alunno) == true ? (
                                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: alunno?.presenza?.statoPresenza !== "P" && alunno?.presenza?.statoPresenza !== "ND" && alunno?.presenza?.statoPresenza !== "A" ? 'pointer' : 'default' }}
                                    onClick={() => alunno?.presenza?.statoPresenza !== "P" && alunno?.presenza?.statoPresenza !== "ND" && alunno?.presenza?.statoPresenza !== "A" ? vediDettaglioAlunno(alunno?.id, 2, alunno?.nome, alunno?.cognome, false, undefined, alunno?.presenza) : null}
                                  >
                                    <Presenza alunno={alunno} isRegistroDettaglio={true} />
                                  </Box>
                                ) : null}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>

                      </Table>
                    </TableContainer>
                  ) : (
                    <Box height={"70vh"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                      <Typography variant="h5">Per la data selezionata (<b>{(getDayMonthAndYearErrorDialog(dataRichiesta))}</b>) non è possibile visualizzare il registro giornaliero</Typography>
                      <Button sx={{ mt: 2 }} onClick={goBackToVistaSintetica} variant="contained" startIcon={<ArrowBack />}>
                        Torna alla vista sintetica
                      </Button>
                    </Box>
                  )
                }
              </Box >
            ) : (
              <Box className="centered-column-direction" sx={{ minHeight: '80vh' }}>
                <CircularProgress />
                <Typography variant="body2" sx={{ mt: 3 }}>Caricamento registro...</Typography>
              </Box>
            )
          }
        </DialogContent>
      </Dialog>

      <Dialog fullWidth maxWidth="lg" open={dialogElencoOreFirmaOpen}>
        <DialogContent>
          <ElencoOreDaFirmare closeDialogElencoOre={openElencoOreFirma} idDocente={idDocente} dataRichiesta={dataRichiesta} />
        </DialogContent>
      </Dialog>

      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={3000} onClose={() => { setAlertOpen(false) }}>
        <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
          {alertMsg}
        </Alert>
      </Snackbar>


    </Fragment >

  );
}



