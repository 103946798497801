import { useEffect, useState } from "react"
import { Alert, Box, Button, Chip, Grid } from '@mui/material';
import { itIT } from "@mui/x-date-pickers/locales";
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime, Interval } from "luxon";
import { UtilityAPI } from "../../api/utilityApi";
import { getDataInizioFinePeriodo } from "./utilityMethods";
import { convertDataGetProssimeLezione } from "./metodiDateUtility";

export default function RangeDate(props) {
    const [nGiorniSelezionati, setNGiorniSelezionati] = useState(7);
    const [canSelectSingleDay, setCanSelectSingleDay] = useState(false);

    const [inizioPeriodo, setInizioPeriodo] = useState(null);
    const [finePeriodo, setFinePeriodo] = useState(null)
    const [isPeriodoSelezionatoCorrente, setIsPeriodoSelezionatoCorrente] = useState(true);
    const [canShowPeriodoTirocinio, setCanShowPeriodoTirocinio] = useState(false);
    const [dataInizioMin, setDataInizioMin] = useState(null);
    const [dataFineMax, setDataFineMax] = useState(null);

    const [dataInizioSupporto, setDataInizioSupporto] = useState(null);
    const [dataFineSupporto, setDataFineSupporto] = useState(null);

    const [trimestreNome, setTrimestreNome] = useState("");
    const [meseSelezionato, setMeseSelezionato] = useState("");

    const [dateTirocinio, setDateTirocinio] = useState(null);
    const [trimestri, setTrimestri] = useState([]);
    const [showOnlyPeriodi, setShowOnlyPeriodi] = useState(false);

    const [canShowDateGreaterThanToday, setCanShowDateGreaterThanToday] = useState(true);

    useEffect(() => {
        let objDateInteresse = getDataInizioFinePeriodo();
        let dataDiInteresseTmp = objDateInteresse?.dataDiInteresse ?? DateTime.now();
        let inizioPeriodoTmp = objDateInteresse?.inizioPeriodo ?? null
        setInizioPeriodo(inizioPeriodoTmp ?? null);
        let finePeriodoTmp = objDateInteresse?.finePeriodo ?? null;
        const today = DateTime.now();
        setFinePeriodo(finePeriodoTmp ?? null);
        setIsPeriodoSelezionatoCorrente(objDateInteresse?.isAnnoFormativoCorrente ?? true)
        setMeseSelezionatoString(dataDiInteresseTmp.toLocaleString({ month: 'long' }));
    }, [])

    useEffect(() => {
        if (props.canShowDateGreaterThanToday === false) {
            let objDateInteresse = getDataInizioFinePeriodo();
            let finePeriodoTmp = objDateInteresse?.finePeriodo ?? null;
            const today = DateTime.now();
            if (finePeriodoTmp > today); //Altrimenti non faccio niente. Già l'ho settata allo useEffect principale
            setFinePeriodo(today);
        }
    }, [props.canShowDateGreaterThanToday])

    useEffect(() => {
        if (props.canSelectSingleDay !== undefined)
            setCanSelectSingleDay(props.canSelectSingleDay)
    }, [props.canSelectSingleDay])

    useEffect(() => {
        if (props.showOnlyPeriodi !== undefined) {
            setShowOnlyPeriodi(props.showOnlyPeriodi)
        }
    }, [props.showOnlyPeriodi])

    useEffect(()=>{
        if(props.showPeriodoTirocinio)
            setCanShowPeriodoTirocinio(props?.showPeriodoTirocinio ?? false);
    },[props.showPeriodoTirocinio])

    useEffect(() => {
        if (props.idClasse !== undefined) {
            getTrimestri(props.idClasse)
        }
    }, [props.idClasse])

    useEffect(() => {
        if (props.dataDiInteresseInizio !== undefined && props.dataDiInteresseInizio != null) {
            let converted = DateTime.fromISO(props.dataDiInteresseInizio);
            //setDataInizio(converted)
            setDataInizioSupporto(converted);
        }
    }, [props.dataDiInteresseInizio])

    useEffect(() => {
        if (props.dataDiInteresseFine !== undefined && props.dataDiInteresseInizio !== undefined) {
            //Arriva in ISO
            if (props.dataDiInteresseFine !== props.dataDiInteresseInizio) {
                let converted = DateTime.fromISO(props.dataDiInteresseFine);
                //converted = converted.plus({ days: 1 })
                //setDataFine(converted)
                setDataFineSupporto(converted);

            } else setDataFineSupporto(null)
        }
    }, [props.dataDiInteresseFine])


    useEffect(() => {
        if (props.dataInizioMin !== undefined)
            setDataInizioMin(props?.dataInizioMin ?? null);
    }, [props.dataInizioMin])

    useEffect(() => {
        if (props.dataFineMax !== undefined)
            setDataFineMax(props?.dataFineMax ?? null);
    }, [props.dataFineMax])

    function confirmPeriodo() {
        if (props.confirmPeriodo) {
            let dataInizioFormatted = convertDataGetProssimeLezione(dataInizioSupporto, true);
            let dataFineFormatted = null;
            if (dataFineSupporto !== null) {
                dataFineFormatted = convertDataGetProssimeLezione(dataFineSupporto, true);
            } else {
                dataFineFormatted = convertDataGetProssimeLezione(dataInizioSupporto, true);
            }
            props.confirmPeriodo(dataInizioFormatted, dataFineFormatted, trimestreNome);
        }
    }



    function changeDataInizio(value) {
        setDataInizioSupporto(value);
        if (dataFineSupporto != null) {
            let interval = Interval.fromDateTimes(value, dataFineSupporto);
            let differenza = Math.round(interval.length('days'));
            setNGiorniSelezionati(differenza);
        } else {
            setNGiorniSelezionati(0);
        }
    }
    function changeDataFine(value) {
        setDataFineSupporto(value)
        let interval = Interval.fromDateTimes(dataInizioSupporto, value);
        let differenza = Math.round(interval.length('days'));
        setNGiorniSelezionati(differenza);
    }

    function selectOggi() {
        setDataInizioSupporto(DateTime.now())
        setDataFineSupporto(null)
        setNGiorniSelezionati(1);
    }
    function selectSettimana() {
        let dataAttuale = DateTime.now(); //dataInizio ?? DateTime.now();
        if (!isPeriodoSelezionatoCorrente) { dataAttuale = inizioPeriodo }
        setDataInizioSupporto(dataAttuale.startOf('week'))
        setDataFineSupporto(dataAttuale.endOf('week'))
        let interval = Interval.fromDateTimes(dataAttuale.startOf('week'), dataAttuale.endOf('week'));
        let differenza = Math.round(interval.length('days'));
        setNGiorniSelezionati(differenza);
    }
    function selectMese() {
        let dataAttuale = DateTime.now();//dataInizio ?? DateTime.now();
        if (!isPeriodoSelezionatoCorrente) { dataAttuale = inizioPeriodo }
        setDataInizioSupporto(dataAttuale.startOf('month'))
        setDataFineSupporto(dataAttuale.endOf('month'))
        let interval = Interval.fromDateTimes(dataAttuale.startOf('month'), dataAttuale.endOf('month'));
        let differenza = Math.round(interval.length('days'));
        setNGiorniSelezionati(differenza);
    }

    function selectTrimestre(dataInizioTrimestre, dataFineTrimestre, trimestreNomeTmp) {

        let dataInizioParsed = DateTime.fromISO(dataInizioTrimestre);
        let dataFineParsed = DateTime.fromISO(dataFineTrimestre);

        setDataInizioSupporto(dataInizioParsed)
        setDataFineSupporto(dataFineParsed)
        setTrimestreNome(trimestreNomeTmp)

        let interval = Interval.fromDateTimes(dataInizioParsed, dataFineParsed);
        let differenza = Math.round(interval.length('days'));
        setNGiorniSelezionati(differenza);
    }


    function selectPeriodoTirocinio(dataInizioTrimestre, dataFineTrimestre) {

        let dataInizioParsed = DateTime.fromISO(dataInizioTrimestre);
        let dataFineParsed = DateTime.fromISO(dataFineTrimestre);

        setDataInizioSupporto(dataInizioParsed)
        setDataFineSupporto(dataFineParsed)
        setTrimestreNome("Periodo di tirocinio")

        let interval = Interval.fromDateTimes(dataInizioParsed, dataFineParsed);
        let differenza = Math.round(interval.length('days'));
        setNGiorniSelezionati(differenza);
    }

    function setMeseSelezionatoString(value) {
        if (value !== null) {
            let meseSelezionatoTmp = value//value.format('MMMM').toString();
            setMeseSelezionato(meseSelezionatoTmp);
        }
        else setMeseSelezionato("");
    }

    async function getTrimestri(idCorso) {
        UtilityAPI.getTrimestriCorso(idCorso)
            .then((result) => {
                setDateTirocinio(result?.tirocinio ?? null)
                if (result.trimestri.length > 0) {
                    let trimestriTmp = result.trimestri;
                    let trimestreCorrente = null;
                    if (result.trimestreCorrente.length > 0) {
                        trimestreCorrente = result.trimestreCorrente[0];
                        trimestreCorrente.ordinale = 0;
                        trimestriTmp.push(trimestreCorrente)
                    }
                    setTrimestri([...trimestriTmp])
                    if (showOnlyPeriodi) {
                        for (let t of trimestriTmp) {
                            if (t.ordinale === 0) selectTrimestre(t.dataInizio, t.dataFine, t.nome)
                        }
                    }
                } else {
                    setTrimestri([])
                }
            })
            .catch((error) => {
                if (error.response) {
                }
            })
    }

    return (
        <Grid container spacing={2} alignItems={"center"}>

            {/*Se è il periodo corrente c'è oggi, questa settimane e questo mese, altrimenti se ho i trimestri mostro anche quelli. Se nessuna di queste condizioni sono
verificate allora non renderizzo niente */}
            {(isPeriodoSelezionatoCorrente || trimestri.length > 0) && (
                <Grid item xs={12} md={4} sx={{ display: "flex", flexDirection: { xs: 'row', md: 'column' }, flexWrap: 'wrap', justifyContent: 'center' }}>
                    {(!showOnlyPeriodi && isPeriodoSelezionatoCorrente) && <Chip sx={{ mr: 2, mb: 1, width: '100%' }} color="default" variant="outlined" label="Oggi" onClick={selectOggi} />}
                    {(!showOnlyPeriodi && isPeriodoSelezionatoCorrente) && <Chip sx={{ mr: 2, mb: 1, width: '100%' }} color="default" variant="outlined" label="Tutta la settimana " onClick={selectSettimana} />}
                    {(!showOnlyPeriodi && isPeriodoSelezionatoCorrente) && <Chip sx={{ mr: 2, mb: 1, width: '100%' }} color="default" variant="outlined" label={"Tutto il mese (" + meseSelezionato + ")"} onClick={selectMese} />}
                    {/* <Chip sx={{ mr: 2, width: 'fit-content' }} color="default" variant="outlined" label={"Tutto il trimestre"} disabled onClick={selectMese} /> */}
                    {trimestri.map((trimestre, index) => (
                        trimestre.ordinale !== 0 ? (
                            <Chip sx={{ mr: 2, mb: 1, width: '100%' }} key={index} color="default" variant="outlined" label={trimestre.nome} onClick={() => selectTrimestre(trimestre.dataInizio, trimestre.dataFine, trimestre.nome)} />
                        ) : (
                            <Chip sx={{ mr: 2, mb: 1, width: '100%' }} key={index} color="default" variant="outlined" label={"Trimestre corrente"} onClick={() => selectTrimestre(trimestre.dataInizio, trimestre.dataFine, trimestre.nome)} />
                        )
                    ))}
                    {(canShowPeriodoTirocinio && dateTirocinio !== null) && (
                        <Chip sx={{ mr: 2, mb: 1, width: '100%' }} color="default" variant="outlined" label={"Periodo di tirocinio"} onClick={() => selectPeriodoTirocinio(dateTirocinio.dataInizio, dateTirocinio.dataFine)} />
                    )}
                </Grid>
            )}
            <Grid item xs={12} md={(isPeriodoSelezionatoCorrente || trimestri.length > 0) ? 8 : 12} >
                <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                    LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                    <Box sx={{ p: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: 'center' }}>
                        <MobileDatePicker views={['month', 'day']} sx={{ width: '100%' }}
                            localeText={{
                                cancelButtonLabel: 'Annulla',
                                okButtonLabel: "Conferma"
                            }}
                            label="Data inizio periodo"
                            disabled={showOnlyPeriodi}
                            value={dataInizioSupporto}
                            format="dd/MM/yyyy"
                            minDate={dataInizioMin != null ? dataInizioMin : inizioPeriodo}
                            maxDate={dataFineSupporto !== null ? dataFineSupporto.minus({ days: 1 }) : finePeriodo}
                            onChange={(value) => changeDataInizio(value)}
                            dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                        <MobileDatePicker
                            localeText={{
                                cancelButtonLabel: 'Annulla',
                                okButtonLabel: "Conferma"
                            }}
                            sx={{ mt: 2, width: '100%' }} views={['month', 'day']}
                            label="Data fine periodo"
                            disabled={dataInizioSupporto == null || showOnlyPeriodi}
                            value={dataFineSupporto}
                            format="dd/MM/yyyy"
                            minDate={dataInizioSupporto !== null ? dataInizioSupporto.plus({ days: 1 }) : inizioPeriodo}
                            maxDate={dataFineMax != null ? dataFineMax : finePeriodo}
                            onChange={(value) => changeDataFine(value)}
                            dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                    </Box>
                </LocalizationProvider>
                {nGiorniSelezionati > 180 ? (
                    <Alert sx={{ mx: 1, display: "flex", alignItems: "center" }} severity="info">È possibile selezionare un periodo massimo di 180 giorni.</Alert>
                ) : null}
            </Grid>

            <Grid item xs={12} sx={{ mt: 1 }}>
                <Button disabled={dataInizioSupporto == null || (!canSelectSingleDay && dataFineSupporto == null) || nGiorniSelezionati > 180
                } fullWidth variant='contained' color="success" onClick={confirmPeriodo}>
                    Conferma periodo
                </Button>
            </Grid>
        </Grid>
    )
}