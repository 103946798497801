import { Fragment, useState, useEffect } from "react";
import CustomTimePicker from "../../shared/customTimePicker";
import CustomDatePicker from "../../shared/customDatePicker";
import { Box, Typography, Button, FormControl, InputLabel, Select, MenuItem, Divider, Alert, FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import DialogConferma from "../../shared/dialogConferma";
import { Check, Edit } from "@mui/icons-material";
import { TirociniAPI } from "../../../api/tirociniApi";
import { convertToItalianTimeFormatted } from "../../shared/metodiDateUtility";
import { DateTime } from "luxon";

export default function FirmaVisitaTutor(props) {

    const [idCorso, setIdCorso] = useState(-1);
    const [dataVisita, setDataVisita] = useState(null);
    const [idVisita, setIdVisita] = useState(null);
    const [primaFascia, setPrimaFascia] = useState(null);
    const [secondaFascia, setSecondaFascia] = useState(null);

    const [oraInizio, setOraInizio] = useState(null);
    const [oraFine, setOraFine] = useState(null);
    const [alunnoSelezionato, setAlunnoSelezionato] = useState(-1);
    const [listAlunni, setListAlunni] = useState([]);
    const [confirmAlertIsOpen, setConfirmAlertIsOpen] = useState(false);
    const [isModificaFirma, setIsModificaFirma] = useState(false);

    const [minTime, setMinTime] = useState(null);
    const [maxTime, setMaxTime] = useState(null);

    const [isError, setIsError] = useState(false);
    const [fasciaSelezionata, setFasciaSelezionata] = useState("")

    useEffect(() => {
        if (props.isModificaFirma !== undefined) setIsModificaFirma(props.isModificaFirma);
    }, [props.isModificaFirma])

    useEffect(() => {
        if (props.idVisita !== undefined) setIdVisita(props.idVisita);
    }, [props.idVisita])

    useEffect(() => {
        if (props.dataVisita !== undefined && props.idCorso !== undefined && props.alunnoSelezionato !== undefined && props.oraInizio !== undefined && props.oraFine !== undefined) {
            setDataVisita(props.dataVisita);
            setIdCorso(props.idCorso);
            setAlunnoSelezionato(props.alunnoSelezionato);
            setOraInizio(props.oraInizio);
            setOraFine(props.oraFine);
            if (props.dataVisita !== null) {
                getElencoAlunniFascie(props.dataVisita, props.idCorso, props.alunnoSelezionato, props.oraInizio, props.oraFine);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dataVisita, props.idCorso, props.alunnoSelezionato, props.oraInizio, props.oraFine])

    // useEffect(() => {
    //     if (props.oraInizio !== undefined && props.oraFine !== undefined) {
    //         setOraInizio(props.oraInizio);
    //         setOraFine(props.oraFine);
    //         fasciaSet(props.oraInizio,props.oraFine)
    //     }
    // }, [props.oraInizio, props.oraFine])

    // useEffect(() => {
    //     if (props.oraFine !== undefined) setOraFine(props.oraFine);
    // }, [props.oraFine])

    function fasciaSet(oraInizio, oraFine, primaFascia, secondaFascia) {
        let primaFasciaTmp = primaFascia;
        let secondaFasciaTmp = secondaFascia;
        let oraInizioTmp = formatOra(convertToItalianTimeFormatted(oraInizio));
        let oraFineTmp = formatOra(convertToItalianTimeFormatted(oraFine));

        let primaFasciaSel = {
            oraInizio: formatOra(primaFasciaTmp.oraInizio),
            oraFine: formatOra(primaFasciaTmp.oraFine),
        }

        let secondaFasciaSel = {
            oraInizio: formatOra(secondaFasciaTmp.oraInizio),
            oraFine: formatOra(secondaFasciaTmp.oraFine),
        }

        let fasciaTmp = '';

        if (isDateInRange(oraInizioTmp, primaFasciaSel.oraInizio, primaFasciaSel.oraFine) === true) fasciaTmp = 'prima';
        else if (isDateInRange(oraInizioTmp, secondaFasciaSel.oraInizio, secondaFasciaSel.oraFine) === true) fasciaTmp = 'seconda';
        else fasciaTmp = '';

        setFasciaSelezionata(fasciaTmp);

        if (fasciaTmp === "prima") {
            const oraInizioFixed = decrementHour(primaFascia.oraInizio)
            const minTimeTmp = formatOra(oraInizioFixed);
            const oraFineFixed = decrementHour(primaFascia.oraFine)
            const maxTimeTmp = formatOra(oraFineFixed);
            setMinTime(minTimeTmp);
            setMaxTime(maxTimeTmp);
        } else if (fasciaTmp === "seconda") {
            const oraInizioFixed = decrementHour(secondaFascia.oraInizio)
            const minTimeTmp = formatOra(oraInizioFixed);
            const oraFineFixed = decrementHour(primaFascia.oraFine)
            const maxTimeTmp = formatOra(oraFineFixed);
            setMinTime(minTimeTmp);
            setMaxTime(maxTimeTmp);
        }
    }

    function isDateInRange(dateToCheck, startDate, endDate) {
        const dt = DateTime.fromISO(dateToCheck);
        const start = DateTime.fromISO(startDate);
        const end = DateTime.fromISO(endDate);

        return dt >= start && dt <= end;
    }

    function timeChanged(e, position) {
        let utcDateTimeTmp = e !== null ? e.toUTC().toISO() : null;
        timeSet(utcDateTimeTmp, position.posizione);
    }

    function onChangeValue(e) {
        let utcDateTmp = e !== null ? e.toUTC().toISO() : null; convertDate(e)
        setAlunnoSelezionato(-1)
        setDataVisita(utcDateTmp);
        getElencoAlunniFascie(utcDateTmp, idCorso, -1)
    }

    function convertDate(data) {
        let utcDateTmp = data !== null ? data.toUTC().toISO() : null;
        return utcDateTmp
    }

    function changeAlunno(e) {
        let alunnoSelezionatoTmp = e.target.value;
        setAlunnoSelezionato(alunnoSelezionatoTmp)
        if (alunnoSelezionatoTmp !== -1)
            getElencoAlunniFascie(dataVisita, idCorso, alunnoSelezionatoTmp)
        setOraInizio(null)
        setOraFine(null)

        setFasciaSelezionata("");
    }

    function changeFasciaSelezionata(e) {
        if (e.target.value !== fasciaSelezionata) {
            setFasciaSelezionata(e.target.value);
            setOraInizio(null);
            setOraFine(null);

            if (e.target.value === "prima") {
                const oraInizioFixed = decrementHour(primaFascia.oraInizio)
                const minTimeTmp = formatOra(oraInizioFixed);
                const oraFineFixed = decrementHour(primaFascia.oraFine)
                const maxTimeTmp = formatOra(oraFineFixed);
                setMinTime(minTimeTmp);
                setMaxTime(maxTimeTmp);
            } else if (e.target.value === "seconda") {
                const oraInizioFixed = decrementHour(secondaFascia.oraInizio)
                const minTimeTmp = formatOra(oraInizioFixed);
                const oraFineFixed = decrementHour(primaFascia.oraFine)
            const maxTimeTmp = formatOra(oraFineFixed);
                setMinTime(minTimeTmp);
                setMaxTime(maxTimeTmp);
            }
        }
    }

    function saveFirmaTutor() {
        if (props.saveFirmaTutor) props.saveFirmaTutor(idVisita, dataVisita, oraInizio, oraFine, alunnoSelezionato, idCorso)
    }

    async function getElencoAlunniFascie(dataVisita, idCorso, idAlunno, oraInizio, oraFine) {
        let dataObj = {
            idCorso: idCorso,
            data: dataVisita,
            idAlunno: idAlunno !== -1 ? idAlunno : null
        }
        TirociniAPI.getElencoAlunniFascie(dataObj)
            .then((result) => {
                let elencoAlunniFascieTmp = result
                if (isError) setIsError(false);
                setListAlunni([...elencoAlunniFascieTmp.alunni]);
                let primaFasciaTmp = elencoAlunniFascieTmp.fascieOrarieAlunno.primaFascia
                setPrimaFascia(primaFasciaTmp);
                let secondaFasciaTmp = elencoAlunniFascieTmp.fascieOrarieAlunno.secondaFascia
                setSecondaFascia(secondaFasciaTmp);

                if (oraInizio !== undefined) fasciaSet(oraInizio, oraFine, primaFasciaTmp, secondaFasciaTmp)

            })
            .catch(function (error) {
                if (error.response) {
                    setIsError(true);
                }
            })
    }

    function closeDialogFirmaOraVisita() {
        if (props.closeDialogFirmaOraVisita) props.closeDialogFirmaOraVisita();
    }

    function closeAlertConfirmInserimento() {
        closeDialogFirmaOraVisita();
        setConfirmAlertIsOpen(false);
    }

    function formatOra(ora) {
        if (ora !== null) {
            let dateFormatted = `2024-01-01T${ora}.000Z`;
            return dateFormatted
        } else {
            return null
        }
    }

    function decrementHour(timeString) {
        // Dividi la stringa in componenti utilizzando il separatore ':'
        const timeParts = timeString.split(':');

        // Ottieni l'ora come numero intero
        let hours = parseInt(timeParts[0], 10);

        // Riduci l'ora di 1, gestendo il caso in cui l'ora sia 0
        hours = (hours - 1 + 24) % 24; // Questo assicura che l'ora torni a 23 se era 0

        // Ricostruisci la stringa, mantenendo il formato a due cifre
        const newTimeString = `${hours.toString().padStart(2, '0')}:${timeParts[1]}:${timeParts[2]}`;

        return newTimeString;
    }

    function timeSet(dataUtc, posizione) {
        if (posizione === 1) {
            setOraInizio(convertToItalianTimeFormatted(dataUtc));
        }
        else {
            setOraFine(convertToItalianTimeFormatted(dataUtc));
        }
    }

    return (
        <Fragment>
            <Box p={2}>

                <Box sx={{ width: '100%', display: 'flex' }}>
                    <CustomDatePicker isFullWidth={true} isDisabled={isModificaFirma} onChangeValue={onChangeValue} selectedValue={dataVisita} />
                </Box>
                {dataVisita !== null && (
                    (listAlunni.length === 0 && dataVisita !== null) ? (
                        <Alert sx={{ mt: 1 }} severity="info">Nella data selezionata non sono attualmente presenti alunni.</Alert>
                    ) : (
                        <Box sx={{ width: '100%', display: 'flex', mt: 2 }}>
                            <FormControl sx={{ width: { xs: '100%', md: '100%' } }}>
                                <InputLabel id="selAlunno-label">
                                    Alunno</InputLabel>
                                <Select
                                    // disabled
                                    data-testid='selAlunno'
                                    label='Alunno '
                                    labelId="selAlunno-label"
                                    value={alunnoSelezionato}
                                    onChange={changeAlunno}
                                    disabled={dataVisita === null || listAlunni.length === 0}
                                >
                                    <MenuItem
                                        key={-1}
                                        value={-1}
                                    >

                                    </MenuItem>
                                    {listAlunni.map((alunno, index) => (
                                        <MenuItem
                                            key={alunno?.id}
                                            value={alunno?.id}
                                        >
                                            {(alunno?.cognome).toUpperCase() + " " + (alunno?.nome).toUpperCase()}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    )
                )}
                {alunnoSelezionato !== -1 && dataVisita !== null && (
                    <Fragment>
                        <FormControl sx={{ mt: 2 }}>
                            <FormLabel id="select-fascia-oraria">Seleziona fascia oraria</FormLabel>
                            <RadioGroup value={fasciaSelezionata} onChange={changeFasciaSelezionata} row aria-labelledby="select-fascia-oraria" name="select-fascia-oraria" >
                                <FormControlLabel disabled={primaFascia?.oraInizio === null && primaFascia?.oraFine === null} value="prima" control={<Radio />} label="Prima fascia" />
                                <FormControlLabel disabled={secondaFascia?.oraInizio === null && secondaFascia?.oraFine === null} value="seconda" control={<Radio />} label="Seconda fascia" />
                            </RadioGroup>
                        </FormControl>

                        <Box sx={{ mt: 2, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CustomTimePicker isFullWidth={true} isDisabled={(alunnoSelezionato === -1 || fasciaSelezionata === null || fasciaSelezionata === "") && oraInizio === null}
                                maxTimeMinusFive={true}
                                referenceDate={dataVisita} increaseDataHour={false}
                                value={oraInizio} minTime={minTime} maxTime={oraFine === null ? maxTime : oraFine} timeChanged={timeChanged} label={'Ora inizio'} position={{ posizione: 1 }} disableKeyboard={true} />
                            <Typography sx={{ mx: 2 }}>/</Typography>
                            <CustomTimePicker isFullWidth={true} isDisabled={alunnoSelezionato === -1 || fasciaSelezionata === null || oraInizio === null}
                                minTimeMinusFive={true}
                                referenceDate={dataVisita} increaseDataHour={false}
                                value={oraFine} minTime={oraInizio} maxTime={maxTime} timeChanged={timeChanged} label={'Ora fine'} position={{ posizione: 2 }} disableKeyboard={true} />
                        </Box>
                    </Fragment>

                )}

            </Box>
            <Divider />
            <Box display={'flex'} flexDirection={{ xs: 'column', md: 'row' }} alignItems={"center"} justifyContent={'end'} sx={{ p: 2 }}>
                <Box display={'flex'} flexDirection={{ xs: 'column', md: 'row' }} justifyContent={'end'} width={'100%'}>
                    <Button onClick={closeDialogFirmaOraVisita}
                        variant='outlined' color="error" sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }}>
                        annulla e chiudi
                    </Button>
                    <Button onClick={() => setConfirmAlertIsOpen(true)}
                        disabled={(oraFine === null || oraInizio === null || oraFine === oraInizio || dataVisita === null)} variant='contained' color={isModificaFirma ? 'warning' : "success"} sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }}>
                        {isModificaFirma ? 'modifica e salva' : 'conferma e salva'}
                    </Button>
                </Box>
            </Box>
            <DialogConferma
                isDialogOpen={confirmAlertIsOpen}
                title={isModificaFirma ? 'Conferma modifica firma ora in azienda' : 'Conferma firma ora in azienda'}
                icon={isModificaFirma ? <Edit sx={{ fontSize: '5rem', my: 2 }} color="warning" /> : <Check sx={{ fontSize: '5rem', my: 2 }} color="success" />}
                text={isModificaFirma ? `Confermi di voler modificare l'ora di visita in azienda?` : `Confermi di voler firmare l'ora di visita in azienda?`}
                showAlunni={false}
                confermaButton={'Conferma'}
                severity={isModificaFirma ? 'warning' : "success"}
                closeDialog={closeAlertConfirmInserimento}
                conferma={saveFirmaTutor}
            />
        </Fragment>
    )
}