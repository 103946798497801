import { Alert, Snackbar, Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Typography, IconButton, DialogActions, Chip } from "@mui/material"
import { Fragment, useState } from "react"
import Breadcrumb from "../../registri/shared/breadcrumb"
// import ElencoCorsiFormatore from "../elencoCorsiFormatore"
import { useNavigate } from "react-router-dom";
import { convertDataDiNascita, convertDataGetProssimeLezione } from "../../shared/metodiDateUtility";
import { TirociniAPI } from "../../../api/tirociniApi";
import { ArrowBack, Check, EditCalendar } from "@mui/icons-material";
import RangeDate from "../../shared/rangeDate";
import { convertISODate } from "../../shared/metodiDateUtility";
import { Help, Close } from "@mui/icons-material";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import DettaglioAlunno from "../../dettaglio-utente/dettaglioAlunno";
import { DateTime } from "luxon";
import TableValidazionePresenzeTutorTirocinio from "./tableValidazionePresenzeTutorTirocinio";
import ElencoCorsiAndTutor from "../elencoCorsiAndTutor";
import DialogConferma from "../../shared/dialogConferma";
import LegendaValidazionePresenzeTutorTirocinio from "./legendaValidazionePresenzeTutorTirocinio";

export default function ValidazionePresenzeTutorTirocinio() {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [hasLoaded, setHasLoaded] = useState(false);
    const [isError, setIsError] = useState(false);

    const [dialogSelezionaCorsoOpen, setDialogSelezionaCorsoOpen] = useState(true)
    const [idCorsoSelezionato, setIdCorsoSelezionato] = useState(-1)
    const [nomeCorso, setNomeCorso] = useState("")
    const [sedeUtente, setSedeUtente] = useState("");

    const [dataDiInteresseInizio, setDataDiInteresseInizio] = useState(null);
    const [dataDiInteresseFine, setDataDiInteresseFine] = useState(null);
    const [formattedDataDiInteresseInizio, setFormattedDataDiInteresseInizio] = useState("");
    const [formattedDataDiInteresseFine, setFormattedDataDiInteresseFine] = useState("");
    const [changePeriodoDiInteresseOpen, setChangePeriodoInteresseOpen] = useState(false)


    const [elencoFirmeTutorTirocinio, setElencoFirmeTutorTirocinio] = useState(null)
    const [listVisite, setListVisite] = useState([]);

    const [totaleOre, setTotaleOre] = useState({});
    // const [canEditFirme, setCanEditFirme] = useState(false);

    const [dialogLegendaOpen, setDialogLegendaOpen] = useState(false);

    const [confirmAlertIsOpen, setConfirmAlertIsOpen] = useState(false);


    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState(false);

    const [isDettaglioAlunnoOpen, setIsDettaglioAlunnoOpen] = useState(false);
    const [alunnoSelezionato, setAlunnoSelezionato] = useState(null);
    const [indexTabDettaglioAlunno, setIndexTabDettaglioAlunno] = useState(0);

    const [idFormatoreSelezionato, setIdFormatoreSelezionato] = useState(-1);

    const [allValidate, setAllValidate] = useState(false);
    const [allGetVisiteAreValidate, setAllGetVisiteAreValidate] = useState(true);
    const [thereAreModifiche, setThereAreModifiche] = useState(false);
    const [indexAllValidate, setIndexAllValidate] = useState(0); //Un index che aggiorno e passo alla row presenza per aggiornare i dati

    function goToHome() {
        navigate("/")
    }

    function openChangeCorso() {
        setDialogSelezionaCorsoOpen(true);
        setHasLoaded(false);
    }

    function changeIdClasseSelezionata(idCorso, nomeCorso, codiceCorso, codiceSimon, nomeSede, dataDiInteresse, idFormatore) {

        setDialogSelezionaCorsoOpen(false);

        setIdCorsoSelezionato(idCorso);
        setNomeCorso(codiceCorso + " - " + nomeCorso + " - " + nomeSede);

        setSedeUtente(nomeSede)

        let dataDiInteresseInizioConverted = convertDataGetProssimeLezione(dataDiInteresse, true);
        setDataDiInteresseInizio(dataDiInteresseInizioConverted);
        let dataDiInteresseInizioFormatted = convertDataDiNascita(dataDiInteresse);
        setFormattedDataDiInteresseInizio(dataDiInteresseInizioFormatted);

        let dataDiInteresseFineTmp = dataDiInteresse.endOf('week');
        let dataDiInteresseFineConverted = convertDataGetProssimeLezione(dataDiInteresseFineTmp, true);
        setDataDiInteresseFine(dataDiInteresseFineConverted);
        let dataDiInteresseFineFormatted = convertDataDiNascita(dataDiInteresseFineTmp);
        setFormattedDataDiInteresseFine(dataDiInteresseFineFormatted);

        setIdFormatoreSelezionato(idFormatore)

        getElencoFirmeTutorTirocinio(idCorso, dataDiInteresseInizioConverted, dataDiInteresseFineConverted, idFormatore);
    }

    function openPeriodoDiInteresseDialog() {
        setChangePeriodoInteresseOpen(true);
    }

    function changePeriodoDiInteresse(dataInizio, dataFine) {
        let today = DateTime.local();
        let dataInizioTmp = dataInizio ?? today.toISO();
        let dataFineTmp = dataFine ?? today.toISO();

        setDataDiInteresseInizio(dataInizioTmp);
        setDataDiInteresseFine(dataFineTmp);

        setFormattedDataDiInteresseInizio(convertISODate(dataInizio));
        setFormattedDataDiInteresseFine(convertISODate(dataFine));

        setChangePeriodoInteresseOpen(false)

        getElencoFirmeTutorTirocinio(idCorsoSelezionato, dataInizio, dataFine);
    }

    function openDialogLegenda() {
        setDialogLegendaOpen(true);
    }
    function closeDialogLegenda() {
        setDialogLegendaOpen(false);
    }

    function openAlertMsg(msg, severity) {
        setAlertMsg(msg);
        setAlertSeverity(severity);
        setAlertOpen(true);
    }

    function modificaValidazioneAlunni(visita, indexVisita) {
        let visiteTmp = [...listVisite]
        visiteTmp[indexVisita] = visita;
        setListVisite([...visiteTmp])
        if (visiteTmp.some(v => v.isModified === true && (v.validata === true || v.annullata === true))) { //Se c'è almeno una presenza che da NON validata è stava validata, allora posso salvare
            if (thereAreModifiche === false)
                setThereAreModifiche(true)
        } else setThereAreModifiche(false)

    }

    function openDettaglioAlunno(alunno, showBesTmp) {
        let alunnoTmp = null;
        if (alunno !== undefined) {
            alunnoTmp = {
                id: alunno?.id,
                nome: alunno?.nome,
                cognome: alunno?.cognome
            }
        }
        if (showBesTmp !== undefined && showBesTmp === true) setIndexTabDettaglioAlunno(4);
        else setIndexTabDettaglioAlunno(0);
        setAlunnoSelezionato(alunnoTmp);
        setIsDettaglioAlunnoOpen(prev => !prev);
    }

    function saveValidazione() {
        let obj = { ...elencoFirmeTutorTirocinio };
        obj.idCorso = idCorsoSelezionato;
        obj.idFormatore = idFormatoreSelezionato;

        obj.visite = listVisite;
        saveValidazioneFirmaTutor(obj)
    }

    function closeAlertConfirmInserimento() {
        setConfirmAlertIsOpen(false);
    }

    function validateAllVisite() {
        let visiteTmp = listVisite
        //setHasLoaded(false);
        for (let i = 0; i < visiteTmp.length; i++) {
            if (!allValidate) { //Valido tutto (tranne cio che è stato validato in precedenza)
                setThereAreModifiche(true)
                if (visiteTmp[i].canEdit === true) {
                    visiteTmp[i].validata = true;
                    visiteTmp[i].isModified = true;

                    visiteTmp[i].annullata = false; // TODO: da controllare
                }
            } else {
                setThereAreModifiche(false) //Metto tutto a "non validato" quindi blocco il pulsante di salvataggio
                if (visiteTmp[i].canEdit === true) {  //Se non è già stava validata procedo
                    visiteTmp[i].validata = false;
                    visiteTmp[i].isModified = false;
                }
            }
        }

        setAllValidate(prev => !prev)
        setListVisite([...visiteTmp])

        setTimeout(() => { //Mi serve per far aggiornare il props pche passo alla row
            setIndexAllValidate(prev => prev + 1)  //setHasLoaded(true);
        }, 100)
    }

    async function getElencoFirmeTutorTirocinio(idCorso, dataInizio, dataFine, idFormatore) {
        let dataObj = {
            idFormatore: idFormatore ?? idFormatoreSelezionato,
            idCorso: idCorso,
            dataDa: dataInizio,
            dataA: dataFine
        }
        setAllValidate(false); //Se faccio un nuovo get imposto a default lo stato che gestisce il pulsante "valida"
        TirociniAPI.elencoFirmeTutorTirocinio(dataObj)
            .then((result) => {

                let visiteTmp = result.visite;
                for (let i = 0; i < visiteTmp.length; i++) {
                    if (visiteTmp[i].validata === true || visiteTmp[i].annullata === true)
                        visiteTmp[i].canEdit = false;
                    else visiteTmp[i].canEdit = true;
                }

                setHasLoaded(true);
                if (isError) setIsError(false);
                setElencoFirmeTutorTirocinio(result)
                setListVisite([...visiteTmp]);
                setTotaleOre({ ...result?.ore });
                // setCanEditFirme(result?.canEditFirme);
                setThereAreModifiche(false);
                if (visiteTmp.some(v => v.validata === false)) {
                    setAllGetVisiteAreValidate(false)
                } else setAllGetVisiteAreValidate(true)

            })
            .catch(function (error) {
                if (error.response) {
                    setHasLoaded(true);
                    setIsError(true);
                }
            })
    }

    async function saveValidazioneFirmaTutor(obj) {
        TirociniAPI.saveValidazioniPresenzeTutor(obj)
            .then((result) => {
                setHasLoaded(true);
                closeAlertConfirmInserimento()
                setThereAreModifiche(false)
                getElencoFirmeTutorTirocinio(idCorsoSelezionato, dataDiInteresseInizio, dataDiInteresseFine);
                openAlertMsg(result.msg, 'success')
            })
            .catch(function (error) {
                if (error.response) {
                    let errorMsg = error?.response?.data?.data[0]?.msg ?? 'Spiacenti, qualcosa è andato storto'
                    openAlertMsg(errorMsg, 'error')
                } else {
                    openAlertMsg('Spiacenti, qualcosa è andato storto', 'error')
                }
            })
    }

    return (
        <Fragment>
            {hasLoaded ? (
                <Fragment>
                    <Breadcrumb sections={["Validazione presenze tutor di tirocinio", nomeCorso]} isTirocinio={true} />
                    <Box sx={{ px: 5, minHeight: '80vh' }}>
                        <Box display={"flex"} flexDirection={{ xs: 'column', md: 'row' }} alignItems={"center"} justifyContent={{ xs: 'start', md: 'space-between' }}>
                            <Box display={"flex"} flexDirection={{ xs: 'column', lg: 'row' }} width={{ xs: '100%', lg: 'unset' }} mb={2}>
                                <Button onClick={openChangeCorso} sx={{ width: { xs: '100%', lg: 'unset' } }} variant="contained" startIcon={<ArrowBack />}>
                                    Torna alla selezione del corso
                                </Button>
                                <Button sx={{ ml: { xs: 0, lg: 2 }, mt: { xs: 1, lg: 0 }, width: { xs: '100%', lg: 'unset' } }} variant="outlined" startIcon={<EditCalendar />} onClick={openPeriodoDiInteresseDialog}>
                                    Cambia periodo di interesse ({formattedDataDiInteresseInizio} - {formattedDataDiInteresseFine})
                                </Button>
                                <Button startIcon={<Help />} sx={{ ml: { xs: 0, lg: 2 }, mt: { xs: 1, lg: 0 }, width: { xs: '100%', lg: 'unset' } }} variant="outlined" color="secondary" onClick={openDialogLegenda}>
                                    Mostra legenda
                                </Button>
                                {listVisite.length > 0 && (
                                    <Fragment>
                                        <Button disabled={allGetVisiteAreValidate} sx={{ ml: { xs: 0, lg: 2 }, mt: { xs: 1, lg: 0 }, width: { xs: '100%', lg: 'unset' } }} variant="outlined" color={allValidate ? "warning" : "success"} onClick={() => validateAllVisite()}>
                                            {allValidate ? 'Non validare tutte le visite visualizzate' : 'Valida tutte le visite visualizzate'}
                                        </Button>
                                        <Button disabled={!thereAreModifiche} sx={{ ml: { xs: 0, lg: 2 }, mt: { xs: 1, lg: 0 }, width: { xs: '100%', lg: 'unset' } }} variant="contained" color="success" onClick={() => setConfirmAlertIsOpen(true)}>
                                            Conferma validazione / annullamento
                                        </Button>
                                    </Fragment>
                                )}
                            </Box>
                        </Box>
                        {listVisite.length > 0 ? (
                            <Fragment>
                                <Box display={"flex"} flexDirection={'row'} width={{ xs: '100%', lg: 'unset' }} justifyContent={'space-between'} alignItems={{ xs: "start", md: 'center' }}>
                                    <Box mr={2}>
                                        <Typography variant={"body2"}>Corso:</Typography>
                                        <Typography color={'primary'}>{(nomeCorso ?? "").toUpperCase()}</Typography>
                                    </Box>
                                    <Box display={"flex"} flexDirection={{ xs: 'column', md: 'row' }} alignItems={'center'}>
                                        <Chip color="primary" sx={{ mr: { xs: 0, md: 1 }, mb: { xs: 0.5, md: 0 }, width: '100%' }} variant="outlined" label={"Ore firmate: " + totaleOre?.oreFirmate} />
                                        <Chip color="success" sx={{ mr: { xs: 0, md: 1 }, mb: { xs: 0.5, md: 0 }, width: '100%' }} variant="outlined" label={"Ore firmate (validate): " + totaleOre?.oreValidate} />
                                        <Chip color="secondary" sx={{ width: '100%' }} variant="outlined" label={"Ore previste: " + totaleOre?.orePreviste} />
                                    </Box>
                                </Box>
                                <Box sx={{ border: '1px solid #eaeaea', borderRadius: '15px', mt: 3, mb: 3, width: '100%', maxHeight: { xs: 'unset', md: '75vh' }, display: "flex", justifyContent: 'center', overflow: 'scroll' }}>
                                    <TableValidazionePresenzeTutorTirocinio nomeCorso={nomeCorso} listVisite={listVisite} modificaValidazioneAlunni={modificaValidazioneAlunni}
                                        openDettaglioAlunno={openDettaglioAlunno} indexAllValidate={indexAllValidate} />
                                </Box>
                            </Fragment>
                        ) : (
                            <Box height={"60vh"} display={"flex"} flexDirection={"column"} justifyContent={"center"}>
                                <Alert severity="info">Nessuna visita di tirocinio nel periodo selezionato ({formattedDataDiInteresseInizio} - {formattedDataDiInteresseFine})</Alert>
                                {/* <Button startIcon={<ArrowBack />} onClick={openChangeCorso} variant="contained" sx={{ mt: 2 }}>  Torna alla selezione del corso</Button>
                                <Button sx={{ mt: 1, width: { xs: '100%', md: 'unset' } }} variant="outlined" startIcon={<EditCalendar />} onClick={openPeriodoDiInteresseDialog}>
                                    Cambia periodo di interesse ({formattedDataDiInteresseInizio} - {formattedDataDiInteresseFine})
                                </Button> */}
                            </Box>
                        )}
                    </Box>
                </Fragment>
            ) : (
                <Box display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"} height={"90vh"}>
                    <CircularProgress />
                    <Typography sx={{ mt: 2 }}>Caricamento...</Typography>
                </Box>
            )}


            <Dialog fullWidth maxWidth={"md"} open={dialogSelezionaCorsoOpen} sx={{ mt: 8 }}>
                <DialogTitle variant="h6"><b>Seleziona corso e formatore</b></DialogTitle>
                <Divider />
                <DialogContent>
                    <ElencoCorsiAndTutor showDateRange={true} 
                        sedeUtente={sedeUtente} goToHome={goToHome} changeCorsoSelezionato={changeIdClasseSelezionata} />
                </DialogContent>
            </Dialog>

            <Dialog open={changePeriodoDiInteresseOpen} >
                <DialogTitle variant="h6">
                    Seleziona il periodo di interesse
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <RangeDate showPeriodoTirocinio idClasse={idCorsoSelezionato} dataDiInteresseInizio={dataDiInteresseInizio} dataDiInteresseFine={dataDiInteresseFine} confirmPeriodo={changePeriodoDiInteresse} />
                </DialogContent>
            </Dialog>

            <Dialog sx={{ marginTop: '7vh' }} fullWidth maxWidth="xl" open={dialogLegendaOpen}>
                <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"} >
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Legenda
                    </Typography>
                    <IconButton onClick={closeDialogLegenda} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <LegendaValidazionePresenzeTutorTirocinio />
                </DialogContent>
                <DialogActions sx={{ p: 3 }}>
                    <Button onClick={closeDialogLegenda} variant="contained" fullWidth color="primary">Chiudi finestra</Button>
                </DialogActions>
            </Dialog>

            <Dialog sx={{ marginTop: '7vh' }} fullWidth maxWidth="xl" open={isDettaglioAlunnoOpen} onClose={openDettaglioAlunno}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={"center"}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Dettaglio alunno ({alunnoSelezionato?.nome + " " + alunnoSelezionato?.cognome})</Typography>
                    <IconButton onClick={openDettaglioAlunno} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ pt: 0 }}>
                    <DettaglioAlunno showBes={false} tabIndex={indexTabDettaglioAlunno} id={alunnoSelezionato?.id} nomeAlunno={alunnoSelezionato?.nome + " " + alunnoSelezionato?.cognome} idCorso={idCorsoSelezionato} />
                </DialogContent>
            </Dialog>

            <DialogConferma
                isDialogOpen={confirmAlertIsOpen}
                title={'Conferma validazione'}
                icon={<Check sx={{ fontSize: '5rem', my: 2 }} color="success" />}
                text={`Confermi di voler validare le firme delle ore di visita in azienda selezionate? Questa operazione è irreversibile.`}
                showAlunni={false}
                confermaButton={'Conferma'}
                severity={"success"}
                closeDialog={closeAlertConfirmInserimento}
                conferma={saveValidazione}
            />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={3000} onClose={() => { setAlertOpen(false) }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>

            <Helmet>
                <title>{t("name")} - Presenze tutor di tirocinio</title>
            </Helmet>
        </Fragment>
    )
}